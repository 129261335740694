import { URL_HOME } from '../common/constantes';
import { HttpClient } from '@angular/common/http';
import { CRUDServiceService } from './crudservice.service';
import { Injectable } from '@angular/core';
import { TipoDocumento } from '../model/tipoDocumento';
import { Observable, take } from 'rxjs';


const PATH = `${URL_HOME}/tipos-documento`;

@Injectable({
  providedIn: 'root'
})
export class TiposDocumentoService<T> extends CRUDServiceService<TipoDocumento> {

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

  public getTipos() : Observable<T[]> {
    return this.http.get<T[]>(`${this.prefix}/tipos`).pipe(take(1));
  }

  public getTiposDocumentos(tipo: string) : Observable<T[]> {
    return this.http.get<T[]>(`${this.prefix}/tiposDocumentos/${tipo}`).pipe(take(1));
  }

}