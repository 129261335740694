<div class="fondo">
    <p-card class="m-4 card flex justify-content-center border-round">
        <ng-template pTemplate="header">
                <img alt="Card" src="assets/images/nueva_imagen_login.png" class="border-round-top relative" style="width: 470px;"/>
        </ng-template>
        
        <div class="grid justify-content-center">
            <form [formGroup]="formGroup" class="w-full" (ngSubmit)="login()" (keydown.enter)="login()">
                <div class="flex flex-column align-items-center ">
                    <span class="z-1 mt-0 font-bold">Acceso a PGPP</span>
                    <div class="z-1 font-bold font-medium">
                        <h2 style="color: red; ">Plataforma de Gestión de Proyectos Públicos</h2>
                    </div>
                </div>
                <div class="flex m-2 flex-column mb-3">
                    <label for="numExpediente" class="mb-1">Usuario</label>
                    <span class="p-input-icon-left">
                        <i class="pi pi-user"></i>
                        <input required="true" formControlName="numExpediente" id="numExpediente" type="text" pInputText class="inputfield w-full" placeholder="Usuario">
                    </span>
                </div>
                <div class="flex m-2 flex-column">
                    <label for="username" class="mb-1">Contraseña</label>
                    <span class="p-input-icon-left">
                        <i class="pi pi-lock z-1"></i>
                        <p-password #password required="true" formControlName="password" [toggleMask]="true" [feedback]="false" placeholder="Contraseña" class="inputfield flex"></p-password>
                    </span>
                    <small [class]="'errorInput'" *ngIf="error === constantesService.INVALID_CREDENTIALS" id="email-help" class="p-invalid">Contraseña incorrecta</small>
                    <small [class]="'errorInput'" *ngIf="error === constantesService.ACCESS_DENIED" id="email-help" class="p-invalid">Usuario no registrado</small>
                </div>
                <div class="col-12 flex justify-content-center">
                    <p-button label="Acceder" class="boton_login" type="submit" [disabled]="!this.formGroup.valid"></p-button>
                </div>
                <div class="col-12 flex justify-content-center">
                    <small>Si tiene problemas para identificarse contacte con su administrador</small>
                </div>
            </form>
        </div>
    </p-card>

    <!-- <div class="m-5 grid justify-content-center"> -->
        <!-- <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-6 ">
                <span class="p-float-label">
                    <input id="firstname6" type="text" pInputText />
                    <label for="firstname6">Firstname</label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input id="lastname6" type="text" pInputText />
                    <label for="lastname6">Lastname</label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <textarea id="address" type="text" rows="4" pInputTextarea></textarea>
                    <label for="address">Address</label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <input id="city" type="text" pInputText class="inputfield w-full">
                    <label for="city">City</label>
                </span>
            </div>
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <p-dropdown
                        inputId="state"
                        [options]="states"
                        [(ngModel)]="selectedState"
                        placeholder="Select"
                        optionLabel="name"
                        [filter]="true"
                        filterBy="name"
                        [showClear]="true" >
                    </p-dropdown>
                    <label for="state">State</label>
                </span>
            </div>
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <input id="zip" type="text" pInputText />
                    <label for="zip">Zip</label>
                </span>
            </div>
        </div>
    </div>

    <div class="card">
        <p-table #tabla [value]="this.usuarios" 
                dataKey="numExpediente"
                [rows]="10"
                [tableStyle]="{ 'min-width': '50rem' }" 
                [paginator]="true"
                [rows]="5"
                [globalFilterFields]="['numExpediente', 'nombre', 'apellidos']">
            <ng-template pTemplate="header">
                <tr>
                    <th>Número de expediente</th>
                    <th>Nombre</th>
                    <th>Apellidos</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="caption">
                <div class="flex">
                    <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="tabla.clear()"></button>
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')" placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{ product.numExpediente }}</td>
                    <td>{{ product.nombre }}</td>
                    <td>{{ product.apellidos }}</td>
                </tr>
            </ng-template>
        </p-table> -->
    <!-- </div> -->
</div>