import { Injectable } from '@angular/core';
import { URL_HOME } from '../common/constantes';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CRUDServiceService } from './crudservice.service';
import { map, Observable, take } from 'rxjs';
import { FileWithType } from '../model/FileWithType';
import { TreeNode } from 'primeng/api';

const PATH = `${URL_HOME}/ficheros`;

@Injectable({
  providedIn: 'root'
})
export class FicheroService extends CRUDServiceService<any> {

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

  upload(fichero: FileWithType, proyecto: any): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('fichero', fichero.file, fichero.file.name);
    formData.append('fileType', fichero.tipo.id); // Agrega el tipo de archivo
    formData.append('ruta', proyecto.nombre + fichero.ruta); // Agrega el tipo de archivo
    formData.append('projectId', proyecto.id?.toString());    

    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });

    return this.http.post(`${this.prefix}/upload`, formData, { headers }).pipe(take(1));
  }

  download(nodo: any) {
    let llamada;
    if (typeof nodo === 'string') {
      const params : HttpParams = new HttpParams().set('ruta', nodo);
      llamada = this.http.get(`${this.prefix}/downloadCarpeta`, {
        params: params,
        responseType: 'blob'
      });
    } else {
      llamada = this.http.get(`${this.prefix}/download/${nodo}`, {
        responseType: 'blob' // Recibe la respuesta como un Blob
      });
    }
    return llamada.pipe(map((res: Blob) => {
        return res;
      })
    );
  }

  getArbolFicheros(): Observable<TreeNode[]> {
    return this.http.get<TreeNode[]>(`${this.prefix}/getArbolFicheros`).pipe(take(1));
  }

  public getDocumentosProyecto(idProyecto: number, tipo: string): Observable<any[]> {
    const formData: FormData = new FormData();
    formData.append('idProyecto', idProyecto.toString());
    formData.append('tipo', tipo);

    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });
    
    return this.http.post<any[]>(`${PATH}/documentosProyecto`, formData, { headers }).pipe(take(1));
  }

  
  obtenerHijos(proyecto: any, ruta: string) {
    const params : HttpParams = new HttpParams().set('ruta', ruta).set('idProyecto', proyecto.id);
    return this.http.get(`${this.prefix}/getChildren`, {params}).pipe(take(1));
  }

  createCarpeta(ruta: string) {
    const params : HttpParams = new HttpParams().set('ruta', ruta);
    return this.http.get(`${this.prefix}/createCarpeta`, {params}).pipe(take(1));
  }
  
  deleteCarpeta(ruta: string) {
    const params : HttpParams = new HttpParams().set('ruta', ruta);
    return this.http.get(`${this.prefix}/deleteCarpeta`, {params}).pipe(take(1));
  }

  updateCarpeta(rutaNew: string, rutaOld: string) {
    const params : HttpParams = new HttpParams()
      .set('rutaNew', rutaNew)
      .set('rutaOld', rutaOld);
    return this.http.get(`${this.prefix}/updateCarpeta`, {params}).pipe(take(1));
  }

}