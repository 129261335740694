import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Miga } from '../model/miga';

@Injectable({
  providedIn: 'root'
})
export class MigaService {

  private _miga = new BehaviorSubject<Miga | null>(null);

  get getMiga(): Observable<Miga | null> {
    return this._miga.asObservable();
  }

  setMiga(miga: Miga) {
    this._miga.next(miga);
  }
}
