import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListadoTareasComponent } from './tareas/listado-tareas/listado-tareas.component';
import { ListadoBeneficiariosComponent } from './beneficiarios/listado-beneficiarios/listado-beneficiarios.component';
import { RoleGuard } from 'src/app/services/role-service';
import { Role } from 'src/app/model/session';
import { EdicionBeneficiariosComponent } from './beneficiarios/listado-beneficiarios/edicion-beneficiarios/edicion-beneficiarios.component';
import { EdicionTareasComponent } from './tareas/listado-tareas/edicion-tareas/edicion-tareas.component';
import { ListadoTiposDocumentoComponent } from './tipos-documento/listado-tipos-documento.component';
import { EdicionTiposDocumentoComponent } from './tipos-documento/edicion-tipos-documento/edicion-tipos-documento.component';
import { ListadoConceptosSalarialesComponent } from './conceptos-salariales/listado-conceptos-salariales.component';
import { EdicionConceptosSalarialesComponent } from './conceptos-salariales/edicion-conceptos-salariales/edicion-conceptos-salariales.component';
import { ListadoUsuariosComponent } from './usuarios/listado-usuarios.component';
import { EdicionUsuariosComponent } from './usuarios/edicion-usuarios/edicion-usuarios.component';
import { ConfiguracionGeneralComponent } from './configuracion-general/configuracion-general.component';

const routes: Routes = [
  { path: 'tareas', data:{urlMiga: "configuracionTareas"}, component: ListadoTareasComponent },
  { path: 'beneficiarios', data:{urlMiga: "configuracionBeneficiarios"}, component: ListadoBeneficiariosComponent },
  { path: 'nuevo-beneficiario', canActivate: [RoleGuard], data: { roles: [Role.ADMIN], urlMiga: "beneficiarioNuevo" }, component: EdicionBeneficiariosComponent },
  { path: 'edicion-beneficiario/:id', canActivate: [RoleGuard], data: { roles: [Role.ADMIN], urlMiga: "beneficiarioEdicion" }, component: EdicionBeneficiariosComponent },
  { path: 'nueva-tarea', canActivate: [RoleGuard], data: { roles: [Role.ADMIN], urlMiga: "tareaNueva" }, component: EdicionTareasComponent },
  { path: 'edicion-tarea/:id', canActivate: [RoleGuard], data: { roles: [Role.ADMIN], urlMiga: "tareaEdicion" }, component: EdicionTareasComponent },
  { path: 'tipos-documento', data:{urlMiga: "configuracionTiposDocumento"}, component: ListadoTiposDocumentoComponent },
  { path: 'nuevo-tipo-documento', canActivate: [RoleGuard], data: { roles: [Role.ADMIN], urlMiga: "tipoDocumentoNuevo" }, component: EdicionTiposDocumentoComponent },
  { path: 'edicion-tipo-documento/:id', canActivate: [RoleGuard], data: { roles: [Role.ADMIN], urlMiga: "tipoDocumentoEdicion" }, component: EdicionTiposDocumentoComponent },
  { path: 'conceptos-salariales', data:{urlMiga: "configuracionConceptosSalariales"}, component: ListadoConceptosSalarialesComponent },
  { path: 'nuevo-concepto-salarial', canActivate: [RoleGuard], data: { roles: [Role.ADMIN], urlMiga: "conceptoSalarialNuevo" }, component: EdicionConceptosSalarialesComponent },
  { path: 'edicion-concepto-salarial/:id', canActivate: [RoleGuard], data: { roles: [Role.ADMIN], urlMiga: "conceptoSalarialEdicion" }, component: EdicionConceptosSalarialesComponent },
  { path: 'usuarios', data: {urlMiga: "configuracionUsuarios" }, component: ListadoUsuariosComponent },
  { path: 'nuevo-usuario', canActivate: [RoleGuard], data: { roles: [Role.ADMIN, Role.RRHH], urlMiga: "usuarioNuevo" }, component: EdicionUsuariosComponent },
  { path: 'edicion-usuario/:id', canActivate: [RoleGuard], data: { roles: [Role.ADMIN, Role.RRHH], urlMiga: "usuarioEdicion" }, component: EdicionUsuariosComponent },
  { path: 'general', canActivate: [RoleGuard], data: { roles: [Role.ADMIN, Role.RRHH], urlMiga: "configuracionGeneral" }, component: ConfiguracionGeneralComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfiguracionRoutingModule { }
