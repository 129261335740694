<div *ngIf="miga">
    <img class="logo_camara" src="../../../../assets/images/logo_camara.png" alt="Logo">
    <div class="flex gap-3 align-items-center">
        <h2 class="m-1 ">{{ miga!.nombre }}</h2>
        <i *ngIf="miga.subtitulo?.icon" [class]="miga.subtitulo?.icon"></i>
        <span *ngIf="miga.subtitulo" [class]="miga.subtitulo.class">{{ miga.subtitulo.texto }}</span>
    </div>
    <p-divider></p-divider>
    <div class="flex flex-row mt-3">
        <div *ngFor="let nav of miga.nav; let i = index">
            <a *ngIf="miga.nav!.length - 1 !== i" [routerLink]="nav.enlace">
                {{ nav.texto}}
            </a>
            <span *ngIf="miga.nav!.length - 1 == i" class="active">
                {{ nav.texto }}
            </span>
            <span *ngIf="miga.nav!.length - 1 !== i" class="m-2">
                {{ '>' }}
            </span>
        </div>
        
    </div>
</div>
