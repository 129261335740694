import { Component, OnInit } from '@angular/core';
import { Miga } from 'src/app/model/miga';
import { MigaService } from 'src/app/services/miga.service';

@Component({
  selector: 'app-miga',
  templateUrl: './miga.component.html',
  styleUrl: './miga.component.scss'
})

export class MigaComponent implements OnInit {

  miga!: Miga;

   constructor(public migaService: MigaService) {}

   ngOnInit(): void {
    this.migaService.getMiga.subscribe((results: Miga | null) => this.miga = results!);
   }

}