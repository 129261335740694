import { Component, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-lista-desplegable',
  templateUrl: './lista-desplegable.component.html',
  styleUrl: './lista-desplegable.component.scss',
  host: { '(resolved)': 'onChangeCallback($event)' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListaDesplegableComponent),
      multi: true,
    },
  ],
})
export class ListaDesplegableComponent {

  @Input() data!: any[];
  @Input() filtrarPor!: string;
  @Input() texto: string | undefined;
  @Input() id: string = 'id';
  @Input() seFiltra: string = 'true';

  @Output() resolved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropdown') dropdown!: Dropdown;
  onChangeCallback = (_: any) => {};
  onTouch = () => {};

  valor:any;

  change(select: any) {
    this.resolved.emit(select.value);
  }
  
  writeValue(obj: any): void {
    if (obj != null) {
      this.valor = obj;
      //this.dropdown.clear();
    }
     //else {
      //this.dropdown?.writeValue(obj);
      //this.valor = obj;
    //}
    // Si no es number o string, es un array
  //  if (obj instanceof Object && !!obj[this.filtrarPor]) {
      

  //     this.idBuscado = obj[this.filtrarPor];
  //     this.selectValue();
  //   } else {
  //     this.idBuscado = obj;
  //     this.selectValue();
  //   }
  // }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  ngAfterViewInit() {
    //this.dropdown!.writeValue = this.valor;
  }
  
}
