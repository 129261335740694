import { Component, Input } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-dialog-warning',
    templateUrl: './dialogo-warning.component.html',
    styleUrl: './dialogo-warning.component.scss'
})
export class DialogWarningComponent {
    @Input() header!: string;
    @Input() width: string = '500px';

    constructor(public config: DynamicDialogConfig, private confirmationService: ConfirmationService,
        private ref: DynamicDialogRef
    ) {
    }

    ngOnInit(): void {
        this.confirmationService.confirm({
            header: this.config.header,
            message: this.config.data.texto,
        });
    }

    aceptar(){
      this.ref.close(true);
    }
    
     /* 
    cancelar() {
      this.ref.close(false);
    } */
}
