import { NIVELES_MAX, PORC_CONT_FIJO, PORC_CONT_OTROS } from "../common/constantes";

export class Configuracion {

    nivelesMax?: number;
    tiposContrato!: any[];
    //contratoFijoPorc?: number;
    //contratoOtrosPorc?: number;
 
    setConfiguracion(parametros: any) {
        this.nivelesMax = parametros.find( (a:any) => a.clave == NIVELES_MAX).valor;
        //this.contratoFijoPorc = parametros.find( (a:any) => a.clave == PORC_CONT_FIJO).valor;
        //this.contratoOtrosPorc = parametros.find( (a:any) => a.clave == PORC_CONT_OTROS).valor;
    }
}