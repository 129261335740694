import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss'
})
export class SpinnerComponent {
  isLoading = false;
  private loadingSubscription!: Subscription;

  constructor(private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.loadingSubscription = this.spinnerService.isLoading.subscribe((loading: boolean) => this.isLoading = loading);
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }
}
