import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStorageService } from 'src/app/common/local-storage';
import { ConceptoSalarial } from 'src/app/model/conceptoSalarial';
import { ConceptosSalarialesService } from 'src/app/services/conceptos-salariales.service';
import { DialogConfirmComponent } from 'src/app/ui/common/dialog-confirm/dialog-confirm.component';
import { DialogWarningComponent } from 'src/app/ui/common/dialog-warning/dialogo-warning.component';

@Component({
    selector: 'app-edicion-conceptos-salariales',
    templateUrl: './edicion-conceptos-salariales.component.html',
    styleUrl: './edicion-conceptos-salariales.component.scss'
})

export class EdicionConceptosSalarialesComponent implements OnInit {

    ref: DynamicDialogRef | undefined;

    conceptoSalarialForm: FormGroup;
    isEdit: boolean = false;
    conceptoSalarial: ConceptoSalarial | undefined;

    constructor(
        private fb: FormBuilder,
        public localStorage: LocalStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private conceptosSalarialesService: ConceptosSalarialesService<ConceptoSalarial>,
        private messageService: MessageService,
        public dialogService: DialogService,
    ) {

        this.conceptoSalarialForm = this.fb.group({
            id: new FormControl<string | null>(null),
            nombre: new FormControl<string | null>(null, Validators.required),
            codigo: new FormControl<string | null>(null, Validators.required),
            descripcion: new FormControl<string | null>(null, Validators.required),
            obligatorio: new FormControl<boolean>(false),
            defecto: new FormControl<boolean>(false)
        });

        this.conceptoSalarialForm.get('obligatorio')?.valueChanges.subscribe(value => {
            if (value) {
              this.conceptoSalarialForm.get('defecto')?.setValue(false, { emitEvent: false });
            }
          });
      
          this.conceptoSalarialForm.get('defecto')?.valueChanges.subscribe(value => {
            if (value) {
              this.conceptoSalarialForm.get('obligatorio')?.setValue(false, { emitEvent: false });
            }
          });
    }

    ngOnInit(): void {

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.isEdit = true;
                this.conceptoSalarialForm.patchValue(this.localStorage.get('conceptoSalarialEdicion'));
                this.conceptoSalarial = this.localStorage.get('conceptoSalarialEdicion');
            }
        });

        this.conceptoSalarialForm.patchValue(this.conceptoSalarial!);

    }

    save() {
        const conceptoSalarial: ConceptoSalarial = this.conceptoSalarialForm.value;
        conceptoSalarial.nombre = conceptoSalarial.nombre.trim();
        if (this.isEdit) {
            this.conceptosSalarialesService.update(conceptoSalarial).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Concepto salarial actualizado correctamente' });
                this.router.navigateByUrl('configuracion/conceptos-salariales');
            });
        } else {
            this.conceptosSalarialesService.create(conceptoSalarial).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Concepto salarial creado correctamente' });
                this.router.navigateByUrl('configuracion/conceptos-salariales');
            });
        }
    }

    cancelar() {

        this.ref = this.dialogService.open(DialogConfirmComponent, {
            header: 'Salir de pantalla',
            data: { texto: '¿Está seguro de que quiere salir de la pantalla?' }
        });

        this.ref.onClose.subscribe((data: any) => {
            if (data) {
                //permanecemos en la página
                this.router.navigateByUrl("configuracion/conceptos-salariales");
            }
        });
    }

    borrar () {
        if (this.conceptoSalarial?.proyectosActivos) {
            this.abrirDialogo();

        } else {
            this.ref = this.dialogService.open(DialogConfirmComponent, {
                header: 'Borrado',
                data: { texto: '¿Está seguro de borrar el concepto salarial?' }
            });

            this.ref.onClose.subscribe((data: any) => {
                if (data) {
                    this.conceptosSalarialesService.delete(this.conceptoSalarial!.id).subscribe({
                        next: res => {
                            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Concepto salarial borrado con éxito', life: 3000 });
                            this.router.navigateByUrl('configuracion/conceptos-salariales');
                        },
                        error: error => {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al borrar el concepto salarial', life: 3000 });
                        }
                    });
                }

            });
        }
    }

    abrirDialogo() {
        this.ref = this.dialogService.open(DialogWarningComponent, {
                header: 'Borrado incorrecto',
                width: '500px',
                data: { texto: 'No se pudo borrar el concepto porque esta asociado a proyectos activos' }
            })
    }
}
