import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfiguracionRoutingModule } from './configuracion-routing.module';
import { EdicionBeneficiariosComponent } from './beneficiarios/listado-beneficiarios/edicion-beneficiarios/edicion-beneficiarios.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { PrimeNgModule } from '../core/primeng.core.module';
import { ListadoBeneficiariosComponent } from './beneficiarios/listado-beneficiarios/listado-beneficiarios.component';
import { ListadoTareasComponent } from './tareas/listado-tareas/listado-tareas.component';
import { EdicionTareasComponent } from './tareas/listado-tareas/edicion-tareas/edicion-tareas.component';
import { ListadoTiposDocumentoComponent } from './tipos-documento/listado-tipos-documento.component';
import { EdicionTiposDocumentoComponent } from './tipos-documento/edicion-tipos-documento/edicion-tipos-documento.component';
import { ListadoConceptosSalarialesComponent } from './conceptos-salariales/listado-conceptos-salariales.component';
import { EdicionConceptosSalarialesComponent } from './conceptos-salariales/edicion-conceptos-salariales/edicion-conceptos-salariales.component';
import { ListadoUsuariosComponent } from './usuarios/listado-usuarios.component';
import { EdicionUsuariosComponent } from './usuarios/edicion-usuarios/edicion-usuarios.component';
import { ConfiguracionGeneralComponent } from './configuracion-general/configuracion-general.component';


@NgModule({
  declarations: [
    EdicionBeneficiariosComponent,
    ListadoBeneficiariosComponent,
    ListadoTareasComponent,
    EdicionTareasComponent,
    ListadoTiposDocumentoComponent,
    EdicionTiposDocumentoComponent,
    ListadoConceptosSalarialesComponent,
    EdicionConceptosSalarialesComponent,
    ListadoUsuariosComponent,
    EdicionUsuariosComponent,
    ConfiguracionGeneralComponent
  ],
  imports: [
    CommonModule,
    ConfiguracionRoutingModule,
    PrimeNgModule,
    ReactiveFormsModule,
    HttpClientModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    ToastModule
  ]
})
export class ConfiguracionModule { }
