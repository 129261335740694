import { Injectable } from '@angular/core';
import { URL_HOME } from '../common/constantes';
import { HttpClient } from '@angular/common/http';
import { Tarea } from '../model/tarea';
import { CRUDServiceService } from './crudservice.service';

const PATH = `${URL_HOME}/tareas`;

@Injectable({
  providedIn: 'root'
})
export class TareasService extends CRUDServiceService<Tarea> {

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

  // public desasignarTarea(tarea: Tarea) : Observable<void> {
  //   return this.http.post<void>(`${URL_HOME}/tarea/desasignarTarea`, {nombre:''}).pipe(take(1));
  // }

}
