
//export const URL_HOME = '/api/proyectosPublicos';
export const URL_HOME = '/api/proyectos-publicos';

export const URL_HOME_REL = 'home';
export const URL_IMPUTACIONES = 'imputacion/calendario';
export const URL_PROYECTOS = 'proyectos/listado';
export const URL_GES_DOC = 'arbol-ficheros';

export const ANIO_INICIO = 2020;

export const EST_ACTIVO = "Activo";
export const EST_BORRADOR =  "Borrador";
export const EST_CERRADO =  "Cerrado";

export const EST_IMP_ABIERTA = 'Abierta';
export const EST_IMP_ENTREGADA = 'Entregada';
export const EST_IMP_C_PARTICIAL = 'Cierre parcial';
export const EST_IMP_C_TOTAL = 'Cierre total';
export const TIMEOUT_SPINNER = 500;

export const TIPO_DOC_OPERACIONAL = "Operacional";
export const TIPO_DOC_GENERICA = "Genérica";

export const FOLDER_GENERICOS = 'genéricos';

export const URL_INTRANET = 'https://intranet.camaramadrid.es/inicioLogin.jsp';

export const PORC_CONT_OTROS = 'PORC_CONT_OTROS';
export const PORC_CONT_FIJO = 'PORC_CONT_FIJO';
export const NIVELES_MAX = 'NIVELES_MAX';

export const meses = [{name: "ene.",cabecera: "Ene"}, {name: "feb.",cabecera: "Feb"}, {name: "mar.",cabecera: "Mar"}, {name: "abr.",cabecera: "Abr"}, 
  {name: "may.",cabecera: "May"}, {name: "jun.",cabecera: "Jun"}, {name: "jul.",cabecera: "Jul"},
  {name: "ago.",cabecera: "Ago"}, {name: "sep.",cabecera: "Sep"}, {name: "oct.",cabecera: "Oct"}, {name: "nov.",cabecera: "Nov"},
  {name: "dic.",cabecera: "Dic"}
  ];

export const CALENDER_CONFIG_ES = {
    firstDayOfWeek: 1,
    dayNames: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    dayNamesShort: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
    dayNamesMin: ['L', 'M', 'X', 'J', 'V', 'S', 'D'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
        'Noviembre', 'Diciembre'],
    monthNamesShort: ['E', 'F', 'Mz', 'Ab', 'My', 'Jn', 'Jl', 'Ag', 'S', 'O', 'N', 'D'],
    today: 'Hoy',
    clear: 'Reiniciar',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Semana'
}