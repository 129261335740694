import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Table } from 'primeng/table';
import { PruebaAbertoService } from 'src/app/services/prueba-aberto.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Usuario } from '../../../model/usuario';
import { ConstantesService } from 'src/app/services/constantes-service';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent{
  selectedState: any = null;

  states: any[] = [
      {name: 'Arizona', code: 'Arizona'},
      {name: 'California', value: 'California'},
      {name: 'Florida', code: 'Florida'},
      {name: 'Ohio', code: 'Ohio'},
      {name: 'Washington', code: 'Washington'}
  ];

  password: String = '';
  formGroup!: FormGroup;
  error: any;
  // usuarios: any= [];

  @ViewChild('tabla') dt!: Table;

  constructor(private pruebaService: PruebaAbertoService<any>, protected http: HttpClient, 
              private themeService: ThemeService, private sessionService: SessionService,
              private router: Router,
              public constantesService: ConstantesService) {
      this.formGroup = new FormGroup({
        numExpediente: new FormControl(),
          password: new FormControl()
      });
  }



  // ngOnInit(): void {
  //   this.pruebaService.llamadaBack().subscribe( (a: any) => {
  //     this.usuarios = a;
  //   }
  //     );
  // }
  // changeTheme(theme: string) {
  //   this.theme = theme;
  //   this.themeService.switchTheme(theme);
  // }

  applyFilterGlobal($event: any, stringVal: any) {
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  login() {
    const usuario: Usuario = new Usuario(this.formGroup.value);
    this.sessionService.login(usuario).subscribe({
        next: (response) => {
          console.log("autenticado" + response);
          //se guarda el token en session y se navega a la home
          this.sessionService.saveLoginData(response);
          this.router.navigateByUrl("home");
        },
        error: (error: HttpErrorResponse) => {
          this.error = error.error.error_code;
        } 
    });

  }
}
