import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, viewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStorageService } from 'src/app/common/local-storage';
import { Beneficiario } from 'src/app/model/beneficiario';
import { BeneficiariosService } from 'src/app/services/beneficiarios.service';
import { nifValidator } from 'src/app/services/nif-validator';
import { DialogConfirmComponent } from 'src/app/ui/common/dialog-confirm/dialog-confirm.component';
import { DialogWarningComponent } from 'src/app/ui/common/dialog-warning/dialogo-warning.component';

@Component({
    selector: 'app-edicion-beneficiarios',
    templateUrl: './edicion-beneficiarios.component.html',
    styleUrl: './edicion-beneficiarios.component.scss'
})

export class EdicionBeneficiariosComponent implements OnInit {

    ref: DynamicDialogRef | undefined;

    beneficiarioForm: FormGroup;
    idBeneficiario: any;
    isEdit: boolean = false;
    beneficiario: Beneficiario | undefined;

    constructor(
        private fb: FormBuilder,
        public localStorage: LocalStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private beneficiariosService: BeneficiariosService<Beneficiario>,
        private messageService: MessageService,
        public dialogService: DialogService,
    ) {
        this.beneficiarioForm = this.fb.group({
            nombre: new FormControl<string | null>(null, Validators.required),
            nif: new FormControl<string | null>(null, [nifValidator(), Validators.required]),
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.isEdit = true;
                this.beneficiarioForm.patchValue(this.localStorage.get('beneficiarioEdicion'));
                this.beneficiario = this.localStorage.get('beneficiarioEdicion');
                this.idBeneficiario = params['id'];
            }
        });
    }

    save() {
        const beneficiario: Beneficiario = this.beneficiarioForm.value;
        if (this.isEdit) {
            beneficiario.id = this.idBeneficiario;
            this.beneficiariosService.update(beneficiario).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Beneficiario actualizado correctamente' });
                this.router.navigateByUrl('configuracion/beneficiarios');
            });
        } else {
            this.beneficiariosService.create(beneficiario).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Beneficiario creado correctamente' });
                this.router.navigateByUrl('configuracion/beneficiarios');
            });
        }
    }

    cancelar() {

        this.ref = this.dialogService.open(DialogConfirmComponent, {
            header: 'Salir de pantalla',
            data: { texto: '¿Está seguro de que quiere salir de la pantalla?' }
        });

        this.ref.onClose.subscribe((data: any) => {
            if (data) {
                //permanecemos en la página
                this.router.navigateByUrl("configuracion/beneficiarios");
            }
        });
    }

    borrar = (item: any) => {

        if (this.beneficiario?.tieneImputacion) {
            this.abrirDialogo();

        } else {
            this.ref = this.dialogService.open(DialogConfirmComponent, {
                header: 'Borrado',
                data: { texto: '¿Está seguro de borrar el beneficiario?' }
            });

            this.ref.onClose.subscribe((data: any) => {
                if (data) {
                    this.beneficiariosService.delete(item).subscribe({
                        next: res => {
                            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Beneficiario borrado con éxito', life: 3000 });
                            this.router.navigateByUrl('configuracion/beneficiarios');
                        },
                        error: error => {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al borrar el beneficiario', life: 3000 });
                        }
                    });
                }

            });
        }
    }

    abrirDialogo() {
        setTimeout(() => {
            this.ref = this.dialogService.open(DialogWarningComponent, {
                header: 'Borrado incorrecto',
                width: '500px',
                data: { texto: 'No se pudo borrar el beneficiario porque existen imputaciones asociadas.' }
            });

            this.ref.onClose.subscribe((data: any) => {
                if (data) {
                    
                }
            });
        }, 100);
    }
}
