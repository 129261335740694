import { URL_HOME } from '../common/constantes';
import { HttpClient } from '@angular/common/http';
import { CRUDServiceService } from './crudservice.service';
import { Beneficiario } from '../model/beneficiario';
import { Injectable } from '@angular/core';


const PATH = `${URL_HOME}/beneficiarios`;

@Injectable({
  providedIn: 'root'
})
export class BeneficiariosService<T> extends CRUDServiceService<Beneficiario> {

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

}