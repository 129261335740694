import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";
import { Observable, of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import { LoginResponse, SessionUserData, TokenData } from '../model/session';
import { LocalStorageService } from "../common/local-storage";
import { URL_HOME } from "../common/constantes";
import { Usuario } from "../model/usuario";
import { Roles } from "../model/roles";

export interface Key {
  TOKEN:string;
  USER_ROLE:string;
  USERNAME:string;
  USER_FULLNAME:string;
  USER_ES_ADMIN: string;
  USER_EMAIL:string;
  USER_PERMISSION_MASK:string;
  USER_FULLNAM:string;
  USER_ID:string;
  USER_TEMP_SESSION:string;
  USER_AVATAR:string;
  USER_NOTIFICATION_TOKEN:string;
  USER_CONTRACT:string;
  REDIRECT_URL:string;
  TOKEN_CC: string,
  APP_KEY: string,
  [key: string]: any;
}

export class SessionEventType {
  public static readonly LOGOUT = 'LOGOUT';
  public static readonly CHANGE_ROLE = 'CHANGE_ROLE';
}

const URL_PREFIX_SESSION = URL_HOME;

@Injectable({
  providedIn: 'root',
})  
export class SessionService {
  key: Key = {
    TOKEN: "auth.token",
    USER_ROLE: "user.role",
    USERNAME: "user.username",
    USER_FULLNAME: "user.fullname",
    USER_EMAIL: "user.email",
    USER_PERMISSION_MASK: "user.permission_mask",
    USER_FULLNAM: "user.fullname",
    USER_ID: "user.id",
    USER_TEMP_SESSION: "user.temp_session",
    USER_AVATAR: "user.avatar",
    USER_NOTIFICATION_TOKEN: "user.notification_token",
    USER_CONTRACT: "user.contract",
    USER_ES_ADMIN: "user.es_admin",
    REDIRECT_URL: "navigation.original.url",
    TOKEN_CC: "token_cc",
    APP_KEY: "appkey"
  
  }

  constructor(private http: HttpClient, private storage: LocalStorageService) {}

  public set(key: string, value: any): void {
    this.storage.set(key, value);
  }

  public remove(key: string): any {
    return this.storage.remove(key);
  }

  public get(key: string, defaultValue?: any): any {
    return this.storage.get(key, defaultValue);
  }

  public comprobarToken(token: string, appkey: string): Observable<LoginResponse> {
    let params = new HttpParams().set('token', token).set('appkey', appkey);
    return this.http.post<LoginResponse>(URL_HOME + '/token', null, {params}).pipe(
      take(1)
    );
  }

  /**
   * Comprueba en el servidor si el token es válido
   */
  public isAuthenticated(): Observable<boolean> {
    const token = this.token;
    if (!token) {
      // aqui hay que comprobar si el usuario ya se valido en la intranet y el navegador tiene algún token.
      // si lo tiene, tomarlo.
      return of(false);
    }
    const url = `${URL_PREFIX_SESSION}/validate`;
    // return this.http
    //   .get<any>(url, {
    //     params: { token: token }
    //   })
    let params = new HttpParams().set('tokenCC', this.tokenCC).set('appkey', this.get('appkey'));
      return this.http.post<any>(url, token, {params}).pipe(
        catchError(err => {
          this.token = "";
          return of(false);
        }),
        map(result => result !== false),
        take(1)
      );
  }

  public get username(): string {
    return this.get(this.key.USERNAME);
  }

  public get fulName(): string {
    return this.get(this.key.USER_FULLNAME);
  }

  public get esAdmin(): boolean {
    return this.get(this.key.USER_ES_ADMIN);
  }

  public get userId(): number {
    return this.get(this.key.USER_ID);
  }

  public get token(): string {
    return this.get(this.key.TOKEN);
  }
  
  public get avatar(): string {
    return this.get(this.key.USER_AVATAR);
  }

  public set token(newToken: string) {
    if (newToken == null) {
      this.remove(this.key.TOKEN);
    } else {
      this.set(this.key.TOKEN, newToken);
    }
  }

  public get tokenCC(): string {
    return this.get(this.key.TOKEN_CC);
  }

  public set tokenCC(newToken: string) {
    if (newToken == null) {
      this.remove(this.key.TOKEN_CC);
    } else {
      this.set(this.key.TOKEN_CC, newToken);
    }
  }

  public get contract(): any {
    return this.get(this.key.USER_CONTRACT);
  }

  public get role(): Roles[] {
    return this.get(this.key.USER_ROLE, null);
  }

  public set role(role: Roles[]) {
    if (role == null) {
      this.remove(this.key.USER_ROLE);
    } else {
      this.set(this.key.USER_ROLE, role);
    }
  }

  public get notificationToken(): string {
    return this.get(this.key.USER_NOTIFICATION_TOKEN);
  }

  public set notificationToken(notificationToken: string) {
    if (notificationToken == null) {
      this.remove(this.key.USER_NOTIFICATION_TOKEN);
    } else {
      this.set(this.key.USER_NOTIFICATION_TOKEN, notificationToken);
    }
  }

  public hasRole(role: Roles): boolean {
    return !!this.role.find((a:any) => a.nombre == role);// .includes(role);
  }

  public hasAnyRole(roles: Roles[]): boolean {
    return this.role && roles.findIndex(r => this.hasRole(r)) !== -1;
  }

  /**
   * Remove all session data
   */
  public clear(...excludeKeys: string[]) {
    Object.keys(this.key).forEach(key => excludeKeys.indexOf(this.key[key]) === -1 && this.remove(this.key[key]));
  }

  /**
   * Recibe como parámetro un objeto de tipo LoginResponse que contiene el token que se ha generado y
   * unos datos del usuario. Guarda estos datos en almacenamiento local.
   *
   * @param loginResponse Datos recuperados en el login
   */
  public saveLoginData(loginResponse: LoginResponse) {
    if (!!loginResponse && !!loginResponse.token) {
      this.token = loginResponse.token;
      const tokenData: TokenData = jwtDecode(this.token);;
      const sessionData: SessionUserData = tokenData.session_data;
      this.set(this.key.USER_ID, sessionData.id);
      this.set(this.key.USERNAME, sessionData.email);
      this.set(this.key.USER_FULLNAME, sessionData.full_name);
      //this.set(this.key.USER_PERMISSION_MASK, sessionData.permissions_mask);
      this.set(this.key.USER_ROLE, sessionData.role);
      this.set(this.key.USER_ES_ADMIN, sessionData.esAdmin);
      //this.set(this.key.USER_TEMP_SESSION, sessionData.temp_session);
      //this.set(this.key.USER_AVATAR, sessionData.avatar);
      //this.set(Key.USER_CONTRACT, sessionData.contract);
      //this.bus.broadcast(SessionEventType.CHANGE_ROLE, {role: sessionData.role});

    } else {
      this.clear();
    }
  }


  public login(usuario: Usuario): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(URL_HOME + '/login', usuario).pipe(
      take(1)
    );
  }

  public changeRole(username: string): Observable<LoginResponse> {
    const payload = { username: username };
    return this.http.post<LoginResponse>(`${URL_PREFIX_SESSION}/changeRole`, payload).pipe(take(1));
  }

  public rollBackRole(username: string): Observable<LoginResponse> {
    const payload = { username: username };
    return this.http.post<LoginResponse>(`${URL_PREFIX_SESSION}/rollBackRole`, payload).pipe(take(1));
  }

  public loginGoogle(token: string, url: string): Observable<LoginResponse> {
    const payload = { token: token , avatar_url: url};
    return this.http.post<LoginResponse>(`${URL_PREFIX_SESSION}/loginGoogle`, payload).pipe(take(1));
  }

  public logout(): void {
    this.remove("arbol-ficheros");
    this.clear(this.key.USERNAME,this.key.USER_TEMP_SESSION);
    this.clear(this.key.TOKEN_CC,this.key.TOKEN_CC);
  }
  
  public loginssoEndpoint(): Observable<any> {
    return this.http.get<String>(`${URL_PREFIX_SESSION}/loginms/endpoint`).pipe(take(1));
  }
}
