<div>
    <form [formGroup]="tareaForm" class="flex flex-column gap-3 mt-5" (ngSubmit)="save()">
        <div class="flex flex-row gap-5 p-fluid">
            <div class="field p-field w-6">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="nombre" placeholder="Nombre" />
                    <label for="nombre">Nombre *</label>
                    <div>
                        <small
                            *ngIf="tareaForm.get('nombre')?.invalid && tareaForm.get('nombre')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-6">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="descripcion" placeholder="Descripcion" />
                    <label for="descripcion">Descripción *</label>
                    <div>
                        <small
                            *ngIf="tareaForm.get('descripcion')?.invalid && tareaForm.get('descripcion')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
        </div>
        <div class="flex flex-row gap-5 p-fluid">
            <div class="field p-field w-6">
                <div class="flex gap-1">
                    <p-checkbox formControlName="tieneBeneficiario" [binary]="true" inputId="pi"></p-checkbox>
                    <label for="pi">Tiene beneficiarios</label>
                </div>
            </div>
        </div>
        <div class="flex pt-4 justify-content-end">
            <p-button label="Cancelar" class="mr-2" severity="secondary" (onClick)="cancelar()" />
            <p-button *ngIf="this.isEdit" icon="pi pi-trash"  label="Borrar" (click)="borrar(this.idTarea)" severity="danger" class="mr-2"></p-button>
            <p-button type="submit" icon="pi pi-save" label="Guardar" severity="success" [disabled]="tareaForm.invalid" />
        </div>
    </form>
</div>