
export enum Role {
    ADMIN = 'ADMIN',
    GESTOR = 'GESTOR',
    EMPLEADO = 'EMPLEADO',
    DIRECTOR = 'DIRECTOR',
    RRHH = 'RRHH'
}

export const ROLES_EDICION = [Role.ADMIN, Role.EMPLEADO, Role.GESTOR];

export const ROLES_SIN_PROYECTO = [Role.ADMIN, Role.RRHH, Role.DIRECTOR];

export const ROLES_LECTURA = [Role.RRHH, Role.DIRECTOR];


export class SessionUserData {
    id!: number;
    email!: string;
    full_name!: string;
    username!: string;
    role!: Role[];
    esAdmin!: boolean;
    tokenCC!: string;
    //temp_session!:boolean;
    //permissions_mask!: number;
    //avatar!: string;
    //contract: ContractType;
}

export class LoginResponse {
    token!: string;
    username!: string;
}

// http://server/api/assessment/home/mercedes

export class TokenData {
    session_data!: SessionUserData;
    iat!: number;
    get iat_date() {
        return this.iat && new Date(this.iat);
    }
}

