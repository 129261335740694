import { Injectable } from '@angular/core';
import { CRUDServiceService } from './crudservice.service';
import { Imputacion } from '../model/imputacion';
import { Usuario } from '../model/usuario';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URL_HOME } from '../common/constantes';
import { DiaAgrupado } from '../model/dia';
import { format } from 'date-fns';

const PATH = `${URL_HOME}/imputaciones`;

@Injectable({
  providedIn: 'root'
})
export class ImputacionesService extends CRUDServiceService<Imputacion> {

  private _fechaActual = new BehaviorSubject<Date | null>(null);
  private _usuarioActual = new BehaviorSubject<Usuario | null>(null);
  private _horario = new BehaviorSubject<DiaAgrupado[] | null>(null);

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

  get horario(): Observable<DiaAgrupado[] | null> {
    return this._horario.asObservable();
  }

  set horario(horario: DiaAgrupado[]) {
    this._horario.next(horario);
  }

  get getFechaActual(): Observable<Date | null> {
    return this._fechaActual.asObservable();
  }

  setFechaActual(fecha: Date) {
    this._fechaActual.next(fecha);
  }

  get getUsuarioActual(): Observable<Usuario | null> {
    return this._usuarioActual.asObservable();
  }

  setUsuarioActual(usuario: Usuario) {
    this._usuarioActual.next(usuario);
  }

  obtenerImputacionesAgrupadas(imputacion: Imputacion) : Observable<DiaAgrupado[]> {
    return this.http.post<DiaAgrupado[]>(`${PATH}/getImputacionesAgrupadas`, imputacion).pipe(take(1));
  }

  obtenerImputaciones(imputacion: Imputacion) : Observable<Imputacion[]> {
    return this.http.post<Imputacion[]>(`${PATH}/getImputaciones`, imputacion).pipe(take(1));
  }

  obtenerFestivos(fecha: Date) : Observable<Date[]> {
    return this.http.post<Date[]>(`${PATH}/getFestivos`, fecha).pipe(take(1));
  }

  obtenerHorario(fecha: Date, idUsuario: string) : Observable<DiaAgrupado[]> {
    return this.http.post<DiaAgrupado[]>(`${PATH}/getHorarioMes/${idUsuario}`, fecha).pipe(take(1));
  }

  obtenerPrimerRegistro(idUsuario: string, idProyecto: string) : Observable<Date> {
    return this.http.post<Date>(`${PATH}/getPrimerRegistro/${idUsuario}/${idProyecto}`, null).pipe(take(1));
  }

  guardarImputaciones(imputaciones: Imputacion[], idUsuario: number, fecha: Date): Observable<Imputacion[]> {
    const formattedDate = format(fecha, 'yyyy-MM-dd');
    return this.http.post<Imputacion[]>(`${PATH}/guardarImputaciones/${idUsuario}/${formattedDate}`, imputaciones).pipe(take(1));
  }

  guardarImputacionesMasivas(imputacion: Imputacion, idUsuario: number,
    numeroHoras: string, fechas: any, diasSeleccionados: string[], verificarMasiva: boolean): any {
    const formattedDateIni = format(fechas.fechaIni, 'yyyy-MM-dd');
    const formattedDateFin = format(fechas.fechaFin, 'yyyy-MM-dd');
    
    return this.http.post<Imputacion>(`${PATH}/guardarImputacionesMasivas/${idUsuario}/${numeroHoras}/${formattedDateIni}/${formattedDateFin}/${diasSeleccionados}/${verificarMasiva}`, imputacion).pipe(take(1));
  }

  entregarImputaciones(imputacion: Imputacion): Observable<number> {

    return this.http.post<number>(`${PATH}/entregarImputaciones`, imputacion).pipe(take(1));
  }
}
