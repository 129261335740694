import { Component, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DiaAgrupado } from 'src/app/model/dia';
import { Imputacion } from 'src/app/model/imputacion';
import { Proyecto } from 'src/app/model/proyecto';
import { ImputacionesService } from 'src/app/services/imputaciones.service';

@Component({
  selector: 'app-resumen-horas',
  templateUrl: './resumen-horas.component.html',
  styleUrl: './resumen-horas.component.scss'
})
export class ResumenHorasComponent {
  @Input() diasAgrupados!: DiaAgrupado[];
  
  horasPorProyecto!: Proyecto[];
  responsiveOptions = [
      
      {
        breakpoint: '2200px',
        numVisible: 4,
        numScroll: 1
    }
    ,
      {
        breakpoint: '1900px',
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: '1500px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '1130px',
        numVisible: 1,
        numScroll: 1
    }
  ];

  horarios!: DiaAgrupado[];
  horasImputadasTotales: number = 0;
  horasTotales: number = 0
  constructor(public imputacionesService: ImputacionesService, public router: Router) {
      this.imputacionesService.horario.subscribe(hor => this.horarios = hor!);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const horasPorProyecto: { [key: number]: Proyecto } = {};
    this.horasTotales = 0;
    
    this.horarios?.forEach(dia => this.horasTotales += dia.horas!);

    this.diasAgrupados?.forEach((item:any) => {
      this.horasImputadasTotales = 0;
      if (!!item['proyecto']) {
        this.diasAgrupados.forEach((i:any) => i.fechas.forEach((f:any) => this.horasImputadasTotales+= f.horas));
        this.obtenerPorImputacion(new Imputacion(item), horasPorProyecto);
      } else {
        this.diasAgrupados.forEach((i:any) => i.proyectos.forEach((p:any) => this.horasImputadasTotales+= p.horasImputadas));
        this.obtenerPorDiaAgrupado(new DiaAgrupado(item), horasPorProyecto);        
      }
    });

    this.horasPorProyecto = Object.values(horasPorProyecto);
  }

  obtenerPorImputacion(item: Imputacion, horasPorProyecto: { [key: number]: Proyecto }) {
    if (!horasPorProyecto[item.proyecto!.id]) {
      horasPorProyecto[item.proyecto!.id] = new Proyecto({
        id: item.proyecto!.id,
        nombre: item.proyecto!.nombre,
        horasImputadas: 0,
        color: item.proyecto?.color
      });
    }
    item.fechas?.forEach(f => horasPorProyecto[item.proyecto!.id].horasImputadas! += f.horas!);
    horasPorProyecto[item.proyecto!.id].horasTotales = this.horasTotales;
    horasPorProyecto[item.proyecto!.id].horasRestantes = this.horasTotales - this.horasImputadasTotales;
  }

  obtenerPorDiaAgrupado(item: DiaAgrupado, horasPorProyecto: { [key: number]: Proyecto }) {
    item.proyectos!.forEach(proyecto => {
      if (!horasPorProyecto[proyecto.id]) {
        horasPorProyecto[proyecto.id] = new Proyecto({
            id: proyecto.id,
            nombre: proyecto.nombre,
            horasImputadas: 0,
            color: proyecto.color
        });
      }
      horasPorProyecto[proyecto.id].horasImputadas! += proyecto.horasImputadas!;
      horasPorProyecto[proyecto.id].horasTotales = this.horasTotales;
      horasPorProyecto[proyecto.id].horasRestantes = this.horasTotales - this.horasImputadasTotales;
    });
  }

  navigateToProject(id: string): void {
    this.router.navigateByUrl('/proyectos/gestion/' + id + "/horas-imputadas");
  }
}
