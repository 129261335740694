import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'src/app/common/local-storage';
import { Roles } from 'src/app/model/roles';
import { ROLES_SIN_PROYECTO, Role } from 'src/app/model/session';
import { TableColum } from 'src/app/model/table-column';
import { Tarea } from 'src/app/model/tarea';
import { SessionService } from 'src/app/services/session-service';
import { TareasService } from 'src/app/services/tareas.service';
import { DialogConfirmComponent } from 'src/app/ui/common/dialog-confirm/dialog-confirm.component';
import { DialogWarningComponent } from 'src/app/ui/common/dialog-warning/dialogo-warning.component';
import { TablaGenericaComponent } from 'src/app/ui/common/tabla-generica/tabla-generica.component';

@Component({
  selector: 'app-listado-tareas',
  templateUrl: './listado-tareas.component.html',
  styleUrl: './listado-tareas.component.scss'
})
export class ListadoTareasComponent {

  ref: DynamicDialogRef | undefined;

  tareas: Observable<Tarea[]> | undefined;

  edicion = (item: Tarea) => {
    this.localStorage.set('tareaEdicion', item);
    this.router.navigateByUrl(`/configuracion/edicion-tarea/${item.id}`);
  };

  borrar = (item: Tarea) => {

    if (item.tieneImputacion) {
      this.abrirDialogo();

    } else {
      this.ref = this.dialogService.open(DialogConfirmComponent, {
        header: 'Borrado',
        data: { texto: '¿Está seguro de borrar la tarea?' }
      });

      this.ref.onClose.subscribe((data: any) => {
        if (data) {

          this.tareasService.delete(item.id).subscribe({
            next: res => {
              this.dt.borrarRegistro(item);
              this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tarea borrada con éxito', life: 3000 });
            },
            error: error => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al borrar la tarea', life: 3000 });
            }
          });

        }

      });
    }


  }

  columns: TableColum[] = [];

  @ViewChild('tabla') dt!: TablaGenericaComponent<Tarea>;

  constructor(private tareasService: TareasService, private router: Router,
    public dialogService: DialogService, public messageService: MessageService, private sessionService: SessionService,
    public localStorage: LocalStorageService
  ) {

    this.tareas = this.tareasService.list();

    this.columns = [
      {
        name: "nombre",
        cabecera: "Nombre",
        ancho: 'w-2'
      },
      {
        name: "descripcion",
        cabecera: "Descripción",
        ancho: 'w-5',
        class: () => 'block overflow-hidden text-overflow-ellipsis w-50'
      },
      {
        name: "acciones",
        cabecera: "Acciones",
        filter: false,
        ancho: 'w-1',
        sortable: false,
        exportable: false,
        acciones: [
          {
            // accion editar
            function: this.edicion,
            icon: 'pi iconProConfig',
            tooltip: "Edición",
            condicion: { funcion: this.estadoPermitido, parametros: { roles: [Role.ADMIN] } }
          },
          {
            // accion eliminar
            function: this.borrar,
            icon: 'pi pi-trash',
            tooltip: "Borrar",
            condicion: { funcion: this.estadoPermitido, parametros: { roles: [Role.ADMIN] } }
          }
        ]
      }
    ];
  }

  puedeCrear() {
    return this.sessionService.role.find(rol => rol.nombre == Role.ADMIN);
  }

  abrirDialogo() {
    setTimeout(() => {
      this.ref = this.dialogService.open(DialogWarningComponent, {
        header: 'Borrado incorrecto',
        width: '500px',
        data: { texto: 'No se pudo borrar la tarea porque existen imputaciones asociadas.' }
      });

      this.ref.onClose.subscribe((data: any) => {
        if (data) {

        }

      });
    }, 100); // 3000 milisegundos = 3 segundos
  }

  nuevaTarea() {
    this.localStorage.remove('tareaEdicion');
    this.router.navigateByUrl('/configuracion/nueva-tarea');
  }

  estadoPermitido = (item: Tarea, parametros: any) => {
    let flagRol;
    // comprueba que el usuario tenga el rol adecuado dentro del proyecto
    if (parametros.roles) {
      flagRol = parametros.roles?.find((a: string) =>
        this.sessionService.role.find((b: Roles) => b.nombre == a && (b.proyectos?.find(p => p.id == item.id) || ROLES_SIN_PROYECTO.find(rol => rol == b.nombre))))
    }
    return flagRol;
  }

}
