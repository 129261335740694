<span class="field p-float-label">
    <p-dropdown #dropdown [options]="data"  [(ngModel)]="valor"
                            [optionLabel]="filtrarPor" 
                            [filter]="seFiltra" 
                            [filterBy]="filtrarPor" 
                            [showClear]="true" 
                            placeholder="{{this.texto}}"
                            [dataKey]="id" (onChange)="this.change(dropdown)">
        <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
                <div class="overflow-hidden text-overflow-ellipsis">{{ selectedOption[filtrarPor!] }}</div>
            </div>
        </ng-template>
        <ng-template let-elemento pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div>{{ elemento[filtrarPor!] }}</div>
            </div>
        </ng-template>
    </p-dropdown>
    <label for="departamento">{{texto}}</label>
</span>