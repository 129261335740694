import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ProyectosPublicosCoreModule } from './ui/core/proyectos-publicos.module';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { ConfiguracionModule } from './ui/configuracion/configuracion.module';
import { PendingChangesGuard } from './services/guard';
import { ValidandoComponent } from './ui/common/validando/validando.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json')
}

// const routes: Routes = [
//   { path: 'home', component: HomeComponent }
// ];

registerLocaleData(localeEs);
@NgModule({
  declarations: [
    AppComponent,
    ValidandoComponent
  ],
  imports: [
    ProyectosPublicosCoreModule,
    ConfiguracionModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    ToastModule,
    //RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }})
  ],
  providers: [
    DialogService,
    MessageService,
    PendingChangesGuard,
    { provide: LOCALE_ID, useValue: 'es' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
