import { EST_IMP_ABIERTA } from "../common/constantes";
import { Beneficiario } from "./beneficiario";
import { DiaAgrupado } from "./dia";
import { EntityBase } from "./entityBase";
import { Proyecto } from "./proyecto";
import { Tarea } from "./tarea";
import { Usuario } from "./usuario";

export class Imputacion extends EntityBase {
    usuario!: Usuario;
    proyecto?: Proyecto;
    tarea?: Tarea;
    beneficiario?: Beneficiario;
    periodo?: EntityBase;
    horas?: number;
    fecha!: Date;
    fechas!: DiaAgrupado[];
    //campo para diferenciar las filas de totales en la tabla de imputación
    editable?: boolean = true;
    rowStyle?: string;
    estado: EntityBase = new EntityBase({id: 1, nombre: EST_IMP_ABIERTA});

    constructor(fields: Partial<Imputacion> = {}) {
        super();
        Object.assign(this, fields);
    }
}