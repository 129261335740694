import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogConfirmComponent } from '../ui/common/dialog-confirm/dialog-confirm.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
  comprobarCambios: () => boolean;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(public dialogService: DialogService) {

  }

  ref: DynamicDialogRef | undefined;
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
   // return component.canDeactivate();

    if (component && component.comprobarCambios()) {
      this.ref = this.dialogService.open(DialogConfirmComponent, {
        header: 'Cambios sin guardar', 
        data: {texto: 'Existen cambios sin guardar, ¿quiere salir de la pantalla?'}
      });
      const subject = new Subject<boolean>();

      this.ref.onClose.subscribe((data: any) => {
              if (data) {
                // Permitimos la navegación
                subject.next(true);
              } else {
                // Permanecemos en la página
                subject.next(false);
              }
              subject.complete();  
      });
      return subject.asObservable();
    } else {
      return of(true);
    }
  }
}
