import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog-form',
  templateUrl: './dialog-form.component.html',
  styleUrl: './dialog-form.component.scss'
})
export class DialogFormComponent {
  
  formulario: FormGroup;

  constructor(public config: DynamicDialogConfig, private ref: DynamicDialogRef, private fb: FormBuilder) {
    this.formulario = this.fb.group({});

    this.config.data.forEach((property:any) => 
        this.formulario.addControl(
        Object.keys(property)[0],
        new FormControl<string | null>(null, property.validacion))
    )
  }

  retornar(event?: any, ok?: boolean){
    event?.preventDefault();
    if(this.formulario.valid && ok) {
      this.ref.close(this.formulario.value);
    } else {
      this.ref.close(false);
    }
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj).filter(p => p != 'validacion');
  }
  
}
