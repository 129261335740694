import { Component, Input } from '@angular/core';
import { FicheroService } from 'src/app/services/ficheros.service';
import { TiposDocumentoService } from 'src/app/services/tipos-documento.service';
import { FileWithType } from 'src/app/model/FileWithType';
import { MessagesService } from 'src/app/services/message.service';
import { DialogWarningComponent } from '../dialog-warning/dialogo-warning.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogConfirmComponent } from '../dialog-confirm/dialog-confirm.component';
import { saveAs } from "file-saver";
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-subida-ficheros',
    templateUrl: './subida-ficheros.component.html',
    styleUrl: './subida-ficheros.component.scss'
})
export class SubidaFicherosComponent {

    @Input() proyecto: any;
    @Input() tipo: any;
    @Input() ficheros: any[] = [];
    @Input() carpeta?: TreeNode;
    @Input() ruta!: string;

    filesWithTypes: FileWithType[] = [];
    fileTypes: any;
    ref: DynamicDialogRef | undefined;



    constructor(public dialogService: DialogService, public ficheroService: FicheroService, public tiposDocumentoService: TiposDocumentoService<any>, private messageService: MessagesService) {

    }

    ngOnInit(): void {
        this.tiposDocumentoService.getTiposDocumentos(this.tipo).subscribe((tip: any) => this.fileTypes = tip);
    }

    ngOnChanges(): void {
        this.filesWithTypes = this.ficheros;
    }

    onSelect(event: any) {
        
        for (let file of event.files) {
            if (!this.existeFicheroNombre(file)) {
                const fileWithType: FileWithType = {
                    file: file,
                    tipo: {id: 0},
                    ruta: this.ruta
                };
    
                this.filesWithTypes.push(fileWithType);
            }
        }
    }


    existeFicheroNombre(file: any) {
        if (this.filesWithTypes.find(f => f.file.name === file.name) ||
            this.carpeta?.children?.find((hijo: TreeNode) => file.name.toUpperCase().trim() == hijo.label!.toUpperCase().trim() )) {
            
                this.ref = this.dialogService.open(DialogWarningComponent, {
              header: 'Archivo con el mismo nombre',
              width: '500px',
              data: { texto: 'No se puede añadir la archivo, ya existe una con el mismo nombre' }
            });
            return true;
        }
        return false;
    }

    onUpload(file: any) {
        if (!file.id){
                this.ficheroService.upload(file, this.proyecto).subscribe({
                    next: (response) => {
                        this.filesWithTypes.find(f => f === file)!.id = response.id;
                        this.filesWithTypes.find(f => f === file)!.webUrl = response.webUrl;

                        this.messageService.setMensaje({ severity: 'success', summary: 'Éxito', detail: 'Fichero subido correctamente' });
                    },
                    error: (e) => this.messageService.setMensaje({ severity: 'error', summary: 'Error', detail: 'Error al subir el fichero' })
            });
        } else {
            this.ficheroService.update(file).subscribe({
                next: (response) => this.messageService.setMensaje({ severity: 'success', summary: 'Éxito', detail: 'Fichero modificado correctamente' }),
                error: (e) => this.messageService.setMensaje({ severity: 'error', summary: 'Error', detail: 'Error al modificadar el fichero' })
        });
        }
    }

    removeFile(index: number, fileWithType: any) {
        this.ref = this.dialogService.open(DialogConfirmComponent, {
            header: 'Borrado',
            data: { texto: '¿Está seguro de borrar el archivo?' }
          });
    
          this.ref.onClose.subscribe((data: any) => {
            if (data) {
                this.filesWithTypes.splice(index, 1);

                if (fileWithType.id) {
                    this.ficheroService.delete(fileWithType.id).subscribe({
                        next: (response) => this.messageService.setMensaje({ severity: 'success', summary: 'Éxito', detail: 'Fichero borrado correctamente' }),
                        error: (e) => this.messageService.setMensaje({ severity: 'error', summary: 'Error', detail: 'Error al borrar el fichero' })
                    });
                }
            }
          });
    }

    isImage(file: File): boolean {
        return file.type.startsWith('image/');
    }

    downloadFile(fileWithType: FileWithType) {
        this.ficheroService.download(fileWithType.id!).subscribe({
            next: (response) => {
                saveAs(response, fileWithType.file.name)
                this.messageService.setMensaje({ severity: 'success', summary: 'Éxito', detail: 'Fichero borrado correctamente' });
            },
            error: (e) => this.messageService.setMensaje({ severity: 'error', summary: 'Error', detail: 'Error al borrar el fichero' })
        });
    }
}
