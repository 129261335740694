import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, switchMap } from 'rxjs';
import { LocalStorageService } from 'src/app/common/local-storage';
import { EntityBase } from 'src/app/model/entityBase';
import { Role } from 'src/app/model/session';
import { TableColum } from 'src/app/model/table-column';
import { Usuario } from 'src/app/model/usuario';
import { CosteProyectoService } from 'src/app/services/coste-proyecto.service';
import { nifValidator } from 'src/app/services/nif-validator';
import { SessionService } from 'src/app/services/session-service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { DialogConfirmComponent } from 'src/app/ui/common/dialog-confirm/dialog-confirm.component';
import { DialogWarningComponent } from 'src/app/ui/common/dialog-warning/dialogo-warning.component';
import { TablaGenericaComponent } from 'src/app/ui/common/tabla-generica/tabla-generica.component';

@Component({
    selector: 'app-edicion-usuarios',
    templateUrl: './edicion-usuarios.component.html',
    styleUrl: './edicion-usuarios.component.scss'
})

export class EdicionUsuariosComponent implements OnInit {

    ref: DynamicDialogRef | undefined;

    tiposContrato?: EntityBase[] = [];
    departamentos?: EntityBase[] = [];
    meses!: any[];
    usuarioForm: FormGroup;
    idUsuario: any;
    isEdit: boolean = false;
    usuario: Usuario | undefined;
    costesProyecto = new BehaviorSubject<any[] | null>(null);
    columns: TableColum[] = [];
    
    @ViewChild('tabla') dt!: TablaGenericaComponent<any>;

    constructor(
        private fb: FormBuilder,
        public localStorage: LocalStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private usuarioService: UsuariosService,
        private costeProyectoService: CosteProyectoService,
        private messageService: MessageService,
        public dialogService: DialogService,
        private sessionService: SessionService
    ) {

        const puedeEditar = this.sessionService.role.find(rol => rol.nombre == Role.ADMIN || rol.nombre == Role.RRHH);

        this.usuarioForm = this.fb.group({
            numExpediente: new FormControl<string | null>(null, Validators.required),
            nombre: new FormControl<string | null>(null, Validators.required),
            apellidos: new FormControl<string | null>(null, Validators.required),
            nif: new FormControl<string | null>(null, [nifValidator(), Validators.required]),
            nass: new FormControl<string | null>(null, Validators.required),
            idTipoContrato: new FormControl<EntityBase | null>(null, Validators.required),
            departamento: new FormControl<EntityBase | null>(null),
            email: new FormControl<string | null>(null, Validators.email),
            costePorDefecto: new FormControl<string | null>({ value: null, disabled: !puedeEditar }, Validators.required),
            numSegSocial: new FormControl<string | null>(null)
        });

        this.meses = [{ nombre: 'Enero', cabecera: 'Ene', orden: 1 },
            { nombre: 'Febrero', cabecera: 'Feb', orden: 2 },
            { nombre: 'Marzo', cabecera: 'Mar', orden: 3 },
            { nombre: 'Abril', cabecera: 'Abr', orden: 4 },
            { nombre: 'Mayo', cabecera: 'May', orden: 5 },
            { nombre: 'Junio', cabecera: 'Jun', orden: 6 },
            { nombre: 'Julio', cabecera: 'Jul', orden: 7 },
            { nombre: 'Agosto', cabecera: 'Ago', orden: 8 },
            { nombre: 'Septiembre', cabecera: 'Sep', orden: 9 },
            { nombre: 'Octubre', cabecera: 'Oct', orden: 10 },
            { nombre: 'Noviembre', cabecera: 'Nov', orden: 11 },
            { nombre: 'Diciembre', cabecera: 'Dic', orden: 12 }
        ];
    
    }

    
    esEditable = () => {
        if(this.sessionService.role.find(rol => rol.nombre == Role.ADMIN || rol.nombre == Role.RRHH)){
            return true;
        }
        return false;
    }

    ngOnInit(): void {

        this.usuarioService.getTiposContrato().subscribe((tip: EntityBase[]) => this.tiposContrato = tip);
        this.usuarioService.getDepartamentos().subscribe((dep: EntityBase[]) => this.departamentos = dep);

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.isEdit = true;
                this.usuarioForm.patchValue(this.localStorage.get('usuarioEdicion'));
                this.usuario = this.localStorage.get('usuarioEdicion');
                this.idUsuario = params['id'];
            }
        });

        this.usuarioForm.patchValue(this.usuario!);

        if (this.isEdit) {
            // Define la lista de meses como un objeto de búsqueda
            const mesDict: { [key: number]: string } = this.meses.reduce((acc, mes) => {
                acc[mes.orden] = mes.nombre; // mapea el número del mes a su nombre
                return acc;
            }, {});
            this.costeProyectoService.getCostesProyecto(this.usuario!.id).subscribe(res => {
                // Convertir los campos mes y anio de number a string
                const costesConvertidos = res.map((coste: any) => ({
                    ...coste,
                    mes: mesDict[coste.mes], 
                    anio: coste.anio
                }));

                this.costesProyecto.next(costesConvertidos);
            });
        }

        this.columns = [
            {
                name: "proyecto.nombre",
                cabecera: "Proyecto",
                ancho: 'w-2'
            },
            {
                name: "anio",
                cabecera: "Año",
                ancho: 'w-2',
                format: (item: any) => item.anio + ' '
            },
            {
                name: "mes",
                cabecera: "Mes",
                ancho: 'w-2'
            },
            {
                name: "coste",
                cabecera: "Coste real",
                ancho: 'w-2'
            },
            {
                name: "costeRRHH",
                cabecera: "Coste ajustado",
                ancho: 'w-2',
                sortable: false,
                innerHTML: true,
                format: (item: any) => item.costeRRHH,
                class: (item: any, column: TableColum) => 'p-inputtext p-component p-element celdaEditableUsuarios celda',
                editable: {
                    type: 'input',
                    typeData: 'number',
                    obtenerObj: this.obtenerSelect,
                    editableFun: this.esEditable
                }
            }
        ];

    }

    save() {
        const usuario: Usuario = this.usuarioForm.value;

        usuario.nombre = usuario.nombre.trim();
        
        if (this.isEdit) {
            usuario.id = this.idUsuario;

             // Mapeo inverso: de nombre de mes a número
            const mesDictInverso: { [key: string]: number } = {};
            this.meses.forEach(mes => {
                mesDictInverso[mes.nombre] = mes.orden;
            });

            // Revertir el nombre del mes a su número antes de llamar a actualizarCostes
            const costesConvertidos = this.costesProyecto.value!.map((coste: any) => ({
                ...coste,
                mes: mesDictInverso[coste.mes] // Convertir el nombre del mes a su número
            }));
    
            this.usuarioService.update(usuario)
                .pipe(
                    // SwitchMap para actualizar los costes
                    switchMap(() => this.costeProyectoService.actualizarCostes(costesConvertidos))
                )
                .subscribe(() => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Éxito',
                        detail: 'Usuario actualizado correctamente'
                    });
                    this.router.navigateByUrl('configuracion/usuarios');
                });

        } else {

            this.usuarioService.guardar(usuario).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario creado correctamente' });
                this.router.navigateByUrl('configuracion/usuarios');
            });

        }
    }

    cancelar() {

        this.ref = this.dialogService.open(DialogConfirmComponent, {
            header: 'Salir de pantalla',
            data: { texto: '¿Está seguro de que quiere salir de la pantalla?' }
        });

        this.ref.onClose.subscribe((data: any) => {
            if (data) {
                //permanecemos en la página
                this.router.navigateByUrl("configuracion/usuarios");
            }
        });
    }

    borrar = (item: any) => {

        if (this.usuario?.esAdmin) {
            this.abrirDialogo();

        } else {
            this.ref = this.dialogService.open(DialogConfirmComponent, {
                header: 'Borrado',
                data: { texto: '¿Está seguro de borrar el usuario?' }
            });

            this.ref.onClose.subscribe((data: any) => {
                if (data) {
                    this.usuarioService.delete(item).subscribe({
                        next: res => {
                            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario borrado con éxito', life: 3000 });
                            this.router.navigateByUrl('configuracion/usuarios');
                        },
                        error: error => {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al borrar el usuario', life: 3000 });
                        }
                    });
                }

            });
        }
    }

    abrirDialogo() {
        setTimeout(() => {
            this.ref = this.dialogService.open(DialogWarningComponent, {
                header: 'Borrado incorrecto',
                width: '500px',
                data: { texto: 'No se pudo borrar el usuario porque existen registros asociados.' }
            });

            this.ref.onClose.subscribe((data: any) => {
            });
        }, 100);
    }

    mostrarAsterisco(control: string) {
        if (this.usuarioForm.controls[control].validator) {
            return "*";
        }
        return "";
    }

    obtenerSelect = (item: any, column: TableColum, cambio: any) => {

        // para evitar que al usar los cursores se incrementen o decrementen las horas
        if (cambio && (cambio.originalEvent.key === 'ArrowUp' || cambio.originalEvent.key === 'ArrowDown')) {
            return item.costeRRHH;
        }

        if ((cambio && cambio.value) || cambio?.formattedValue) {
            item.costeRRHH = cambio.value;
        }

        return item.costeRRHH;
    }
}
