<div>
    <form [formGroup]="usuarioForm" class="flex flex-column gap-3 mt-5" (ngSubmit)="save()">
        <div class="flex flex-row gap-5 p-fluid mb-3 mt-4">
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="nombre" placeholder="Nombre" />
                    <label for="nombre">Nombre *</label>
                    <div>
                        <small
                            *ngIf="usuarioForm.get('nombre')?.invalid && usuarioForm.get('nombre')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="apellidos" placeholder="Apellidos" />
                    <label for="apellidos">Apellidos *</label>
                    <div>
                        <small
                            *ngIf="usuarioForm.get('apellidos')?.invalid && usuarioForm.get('apellidos')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-2">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="nif" placeholder="NIF" />
                    <label for="nif">NIF *</label>
                    <div>
                        <small *ngIf="usuarioForm.get('nif')?.invalid && usuarioForm.get('nif')?.touched"
                            class="p-error">
                            NIF no es válido
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-2">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="nass" placeholder="NAF" />
                    <label for="nass">NAF *</label>
                    <div>
                        <small
                            *ngIf="usuarioForm.get('nass')?.invalid && usuarioForm.get('nass')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>
                </span>
            </div>
        </div>
        <div class="flex flex-row gap-5 p-fluid">
            <div class="field p-field w-2">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="numExpediente" placeholder="Número de expediente" />
                    <label for="numExpediente">Numero de expediente *</label>
                    <div>
                        <small
                            *ngIf="usuarioForm.get('numExpediente')?.invalid && usuarioForm.get('numExpediente')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="number" pInputText formControlName="numSegSocial" placeholder="Número de la Seguridad Social" />
                    <label for="numSegSocial">Número de la Seguridad Social</label>
                    <div>
                        <small
                            *ngIf="usuarioForm.get('numSegSocial')?.invalid && usuarioForm.get('numSegSocial')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="email" placeholder="E-mail" />
                    <label for="email">E-mail</label>
                    <div>
                        <small
                            *ngIf="usuarioForm.get('email')?.invalid && usuarioForm.get('email')?.touched"
                            class="p-error">
                            El correo electrónico no es válido 
                        </small>
                    </div>

                </span>
            </div>  
            
            <div class="field p-field w-2">
                <span class="p-float-label ">
                    <input type="number" pInputText formControlName="costePorDefecto" placeholder="Coste por defecto" />
                    <label for="costePorDefecto">Coste por defecto *</label>
                </span>
            </div>
        </div>
        <div class="flex flex-row gap-5 p-fluid mt-3">
            <div class="field p-field w-2">
                <app-lista-desplegable [data]="departamentos!" [seFiltra]="'false'" formControlName="departamento" [filtrarPor]="'nombre'" [texto]="'Departamento  '+ mostrarAsterisco('departamento')"></app-lista-desplegable>
            </div>
            <div class="field p-field w-2">
                <app-lista-desplegable [data]="tiposContrato!" [seFiltra]="'false'" formControlName="idTipoContrato" [filtrarPor]="'nombre'" [texto]="'Tipo de contrato  '+ mostrarAsterisco('idTipoContrato')"></app-lista-desplegable>
            </div>
        </div>
        <div *ngIf="this.isEdit" class="flex flex-column gap-3">
            <app-tabla-generica #tabla [mensaje]="'Costes'" [data]="this.costesProyecto" [columns]="this.columns" [id]="'id'"></app-tabla-generica>
        </div>
        <div class="flex pt-4 justify-content-end">
            <p-button label="Cancelar" class="mr-2" severity="secondary" (onClick)="cancelar()" />
            <p-button *ngIf="this.isEdit" icon="pi pi-trash"  label="Borrar" (click)="borrar(this.idUsuario)" severity="danger" class="mr-2"></p-button>
            <p-button type="submit" icon="pi pi-save" label="Guardar" severity="success" [disabled]="usuarioForm.invalid" />
        </div>
    </form>
</div>