<div>
    <form [formGroup]="conceptoSalarialForm" class="flex flex-column gap-3 mt-5" (ngSubmit)="save()">
        <div class="flex flex-row gap-5 p-fluid">
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="nombre" placeholder="Nombre" />
                    <label for="nombre">Nombre *</label>
                    <div>
                        <small
                            *ngIf="conceptoSalarialForm.get('nombre')?.invalid && conceptoSalarialForm.get('nombre')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="number" pInputText formControlName="codigo" placeholder="Codigo" />
                    <label for="nombre">Código *</label>
                    <div>
                        <small
                            *ngIf="conceptoSalarialForm.get('codigo')?.invalid && conceptoSalarialForm.get('codigo')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-5">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="descripcion" placeholder="Descripción" />
                    <label for="descripcion">Descripción *</label>
                    <div>
                        <small
                            *ngIf="conceptoSalarialForm.get('descripcion')?.invalid && conceptoSalarialForm.get('descripcion')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
        </div>
        <div class="flex flex-row gap-5 p-fluid">
            <div class="flex flex-row align-items-center mb-3">
                <label for="obligatorio" class="mr-2">Obligatorio</label> 
                <p-inputSwitch formControlName="obligatorio"></p-inputSwitch>
                
            </div>
        </div>
        <div class="flex flex-row gap-5 p-fluid">
            <div class="flex flex-row align-items-center mb-3">
                <label for="defecto" class="mr-2">Por defecto</label> 
                <p-inputSwitch formControlName="defecto"></p-inputSwitch>
            </div>
        </div>
        <div class="flex pt-4 justify-content-end">
            <p-button label="Cancelar" class="mr-2" severity="secondary" (onClick)="cancelar()" />
            <p-button *ngIf="this.isEdit" icon="pi pi-trash"  label="Borrar" (click)="borrar()" severity="danger" class="mr-2"></p-button>
            <p-button type="submit" icon="pi pi-save" label="Guardar" severity="success" [disabled]="conceptoSalarialForm.invalid" />
        </div>
    </form>
</div>