import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, take } from 'rxjs';


export type FilterObject = {
  [param: string]: string | string[];
};


export class CRUDServiceService<T> {

  constructor(protected prefix: string, protected http: HttpClient) {
  }

  // public list(filter?: FilterObject) : Observable<T[]> {
  //   const params : HttpParams = new HttpParams({fromObject: filter || {}});
  //   return this.http.get<T[]>(`${this.prefix}/`, { params: params }).pipe(take(1));
  // }
  public list(filter?: FilterObject) : Observable<T[]> {
    const params : HttpParams = new HttpParams({fromObject: filter || {}});
    return this.http.get<T[]>(`${this.prefix}/`, { params: params }).pipe(take(1));
  }

  //Hace la llamada al REST para recuperar el objeto Attribute cuyo id se pasa por parámetro.
  public get(objeto:T): Observable<T> {
      return this.http.post<T>(`${this.prefix}/get`, objeto).pipe(take(1));
  }

  //Hace la llamada al REST para guardar un objeto Attribute nuevo. 
  public create(element: T): Observable<T> {
    return this.http.post<T>(`${this.prefix}/create`, element).pipe(take(1));
    
  }

  //Hace la llamada al REST para guardar los cambios realizados en el objeto Attribute.
  public update(element: T): Observable<T> {
      return this.http.post<T>(`${this.prefix}/update`, element).pipe(take(1));
  }

  //Hace la llamada al REST para eliminar un objeto Attribute (de forma lógica).
  public delete(elementId: number): Observable<void> {
      return this.http.delete<void>(`${this.prefix}/delete/${elementId}`).pipe(take(1));
  }
}
