import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { SessionService } from "./session-service";
import { MigaService } from "./miga.service";
import { ROLES_SIN_PROYECTO } from "../model/session";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  constructor(private _authService: SessionService, public _router: Router, private migaService: MigaService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (!!next.data["roles"] && next.data["roles"].length > 0) {
      if(!this._authService.hasAnyRole(next.data["roles"])){
        this._router.navigate(['/home']);
        return false;
      }
      return this._authService.hasAnyRole(next.data["roles"]);
    } else if (!!next.data["tenerProyecto"]) {
      // comprobar que el usuario tiene permisos en el proyecto
      const idProyecto = Number.parseInt(next.paramMap.get('id')!);
      const tienePermiso = !!this._authService.role.find(rol => rol.proyectos?.find(p => p.id == idProyecto) || ROLES_SIN_PROYECTO.find(sinP => sinP == rol.nombre));
      if (!tienePermiso){
        this._router.navigate(['/home']);
      }
      
      return tienePermiso;
    }

    return true;
  }
}
