import { Injectable } from '@angular/core';
import { URL_HOME } from '../common/constantes';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { CosteHistorico } from '../model/coste-historico';
import { EntityBase } from '../model/entityBase';

const PATH = `${URL_HOME}/conf-general`;

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionGeneralService  {
  [x: string]: any;
  
  constructor(public http: HttpClient) {
  }

  update(parametro: { [key: string]: any }): Observable<Response> {
    return this.http.post<Response>(`${PATH}/modificarValor`, parametro).pipe(take(1));
  }

  obtenerConfiguracion(parametros: string[]): Observable<{[key: string]: string}>  {
    return this.http.post<{[key: string]: string}>(`${PATH}/obtenerConfiguracion`, parametros).pipe(take(1));
  }

  obtenerHistorico(): Observable<CosteHistorico[]> {
    return this.http.get<CosteHistorico[]>(`${PATH}/obtenerHistorico`).pipe(take(1));
  }

  obtenerContratos(): Observable<EntityBase[]> {
    return this.http.get<EntityBase[]>(`${PATH}/obtenerContratos`).pipe(take(1));
  }

  updateTiposContratos(tiposContrato: any[]){
    return this.http.post(`${PATH}/updateTiposContratos`, tiposContrato).pipe(take(1));
  }
}
