import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantesService {

  readonly INVALID_CREDENTIALS: number = 1002;
  readonly ACCESS_DENIED: number = 403;
  

}