import { Injectable } from '@angular/core';
import { Usuario } from '../model/usuario';
import { URL_HOME } from '../common/constantes';
import { CRUDServiceService } from './crudservice.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, take } from 'rxjs';


const PATH = `${URL_HOME}/usuarios`;

@Injectable({
  providedIn: 'root'
})
export class UsuariosService extends CRUDServiceService<Usuario> {

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

  getDelegados(idUsuario:number): Observable<Usuario[]> {
    const params : HttpParams = new HttpParams().append('idUsuario', idUsuario);
    return this.http.get<Usuario[]>(`${PATH}/getDelegados`, {params: params}).pipe(take(1));
  }

  public getTiposContrato() : Observable<any[]> {
    return this.http.get<any[]>(`${this.prefix}/tiposContrato`).pipe(take(1));
  }

  public getDepartamentos() : Observable<any[]> {
    return this.http.get<any[]>(`${this.prefix}/getDepartamentos`).pipe(take(1));
  }

  public guardar(element: Usuario): Observable<Usuario> {
    return this.http.post<Usuario>(`${this.prefix}/guardar`, element).pipe(take(1));
  }

}
