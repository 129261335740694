import { Injectable } from '@angular/core';
import { Usuario } from '../model/usuario';
import { URL_HOME } from '../common/constantes';
import { CRUDServiceService } from './crudservice.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, take } from 'rxjs';


const PATH = `${URL_HOME}/coste-proyecto`;

@Injectable({
  providedIn: 'root'
})
export class CosteProyectoService extends CRUDServiceService<any> {

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

  getCostesProyecto(idUsuario:number): Observable<any[]> {
    const params : HttpParams = new HttpParams().append('idUsuario', idUsuario);
    return this.http.get<Usuario[]>(`${PATH}/getCostesProyecto`, {params: params}).pipe(take(1));
  }

  actualizarCostes(costes: any[] | null) {
    return this.http.post<any[]>(`${PATH}/actualizarCostes`, costes).pipe(take(1));
  }

}
