import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SessionService } from '../services/session-service';
import { MessagesService } from '../services/message.service';
import { TIMEOUT_SPINNER, URL_HOME } from '../common/constantes';
import { SpinnerService } from '../services/spinner.service';


const INVALID_CREDENTIALS_CODE = 1002;
const INVALID_TOKEN_CODE = 1003;
const AUTH_HEADER = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private session!: SessionService;

  constructor(private injector: Injector, private spinnerService: SpinnerService) { }

  excludedsUrl = [
    URL_HOME +'/proyectos/proyectosEstructura',
    URL_HOME +'/imputaciones/getFestivos',
    URL_HOME +'/ficheros/getChildren'
  ];

  urlExcluidas(request: HttpRequest<any>) {
    const urlCurrent: string = request.url;
    for (const item of this.excludedsUrl) {
      if (urlCurrent.indexOf(item) >= 0) {
        return true;
      }
    }
    return false;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.session == null) {
      this.session = this.injector.get(SessionService);
    }
    if (!req.headers.has('Content-Type') && !req.headers.has('enctype')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }
    req = req.clone({
      headers: req.headers
                    .set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache')
    });
    const token = this.session.token;
    if (token) {
      req = req.clone({
        headers: req.headers.set(AUTH_HEADER, `Bearer ${token}`)
      });
    }
    
    let show: any = null
    if (!this.urlExcluidas(req)) {
      show = setTimeout(() => this.spinnerService.show(), TIMEOUT_SPINNER);
    }
    
    return next.handle(req).pipe(
      tap(event => {
        if (event.type === HttpEventType.Response) {
          setTimeout(() => {
            if (show) {
              clearTimeout(show);  
            }
            
            this.spinnerService.hide();});
        }
      }),
      catchError((error: HttpErrorResponse) => {
        setTimeout(() => {
          if (show) {
            clearTimeout(show);  
          }
          this.spinnerService.hide();
        });
        throw error;
      })
    );
  }
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private msgs: MessagesService
  ) {

  }

  // Url´s excluidas de tratar el error
    excludedsUrl = [
      URL_HOME +'/login',
      URL_HOME +'/proyectos/delete',
      URL_HOME +'/ficheros/upload',
      URL_HOME +'/ficheros/update',
      URL_HOME +'/ficheros/delete'
    ];

  urlExcluidas(request: HttpRequest<any>) {
    const urlCurrent: string = request.url;
    for (const item of this.excludedsUrl) {
      if (urlCurrent.indexOf(item) >= 0) {
        return true;
      }
    }
    return false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.urlExcluidas(request)) {
      return next.handle(request);
    } else {
    return next.handle(request).pipe(
        tap(
          (event: HttpEvent<any>) => event,
          (response: HttpErrorResponse) => {
            let msg;
            let redirectToLogin = false;
            if (response.error && response.error.error_msg) {
              const err_data = response.error;
              if (err_data.error_msg) {
                msg = err_data.error_msg;
              }
              if (err_data.error_code === INVALID_TOKEN_CODE || response.status === 401 /*Unauthenticated*/) {
                redirectToLogin = true;
              }
            } else if (response.status === 401 /*Unauthenticated*/) {
              msg = 'Acceso denegado';
              redirectToLogin = true;
            } else {
              if (response.status == 200 || (response.status >= 400 && response.status <= 500)) {
                msg = `Error no esperado accediendo al servidor. Consulte con su administrador.`;
                console.error(response.error);
              } else {              
                msg = 'No fue posible contactar con el servidor';
              }
            }

            this.msgs.setMensaje({ severity: 'error', summary: 'Error', detail: msg });
            console.error(`${msg}. HTTP STATUS: ${response.status}`);
            if (redirectToLogin) {
              this.router.navigateByUrl("/login");
            }
          }));
    } 
  }
}