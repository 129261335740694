import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TIPO_DOC_OPERACIONAL } from 'src/app/common/constantes';
import { FileWithType } from 'src/app/model/FileWithType';
import { Proyecto } from 'src/app/model/proyecto';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-dialog-upload',
  templateUrl: './dialog-upload.component.html',
  styleUrl: './dialog-upload.component.scss'
})
export class DialogUploadComponent {

  proyecto!: Proyecto;
  carpeta!: any;
  filesWithTypes: FileWithType[] = [];
  TIPO_DOC_OPERACIONAL = TIPO_DOC_OPERACIONAL;
  loading = false;

  constructor(public config: DynamicDialogConfig, public ref: DynamicDialogRef, public spinnerService: SpinnerService) {

    this.spinnerService.isLoading.subscribe(bool => this.loading = bool);
    
    this.config.data.forEach((property:any) => {
        this.proyecto = property.proyecto;
        this.carpeta = property.carpeta;
      }
    )
  }

  ngOnDestroy(): void {
    // no se envian los documentos que no se haya definido el tipo, porque no se subieron
    this.ref.close(this.filesWithTypes.filter(file => file.tipo.id != 0));
  }
}
