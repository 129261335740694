import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, viewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStorageService } from 'src/app/common/local-storage';
import { EntityBase } from 'src/app/model/entityBase';
import { TipoDocumento } from 'src/app/model/tipoDocumento';
import { TiposDocumentoService } from 'src/app/services/tipos-documento.service';
import { DialogConfirmComponent } from 'src/app/ui/common/dialog-confirm/dialog-confirm.component';
import { DialogWarningComponent } from 'src/app/ui/common/dialog-warning/dialogo-warning.component';

@Component({
    selector: 'app-edicion-tipos-documento',
    templateUrl: './edicion-tipos-documento.component.html',
    styleUrl: './edicion-tipos-documento.component.scss'
})

export class EdicionTiposDocumentoComponent implements OnInit {

    ref: DynamicDialogRef | undefined;

    tipos?: EntityBase[] = [];
    tipoDocumentoForm: FormGroup;
    idTipoDocumento: any;
    isEdit: boolean = false;
    tipoDocumento: TipoDocumento | undefined;

    constructor(
        private fb: FormBuilder,
        public localStorage: LocalStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private tiposDocumentoService: TiposDocumentoService<TipoDocumento>,
        private messageService: MessageService,
        public dialogService: DialogService,
    ) {

        this.tipoDocumentoForm = this.fb.group({
            nombre: new FormControl<string | null>(null, Validators.required),
            descripcion: new FormControl<string | null>(null, Validators.required),
            tipo: new FormControl<EntityBase | null>(null, Validators.required),
        });
    }

    ngOnInit(): void {
        this.tiposDocumentoService.getTipos().subscribe((tip: EntityBase[]) => this.tipos = tip);

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.isEdit = true;
                this.tipoDocumentoForm.patchValue(this.localStorage.get('tipoDocumentoEdicion'));
                this.tipoDocumento = this.localStorage.get('tipoDocumentoEdicion');
                this.idTipoDocumento = params['id'];
            }
        });

        this.tipoDocumentoForm.patchValue(this.tipoDocumento!);

    }

    save() {
        const tipoDocumento: TipoDocumento = this.tipoDocumentoForm.value;
        tipoDocumento.nombre = tipoDocumento.nombre.trim();
        if (this.isEdit) {
            tipoDocumento.id = this.idTipoDocumento;
            this.tiposDocumentoService.update(tipoDocumento).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tipo de documento actualizado correctamente' });
                this.router.navigateByUrl('configuracion/tipos-documento');
            });
        } else {
            this.tiposDocumentoService.create(tipoDocumento).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tipo de documento creado correctamente' });
                this.router.navigateByUrl('configuracion/tipos-documento');
            });
        }
    }

    cancelar() {

        this.ref = this.dialogService.open(DialogConfirmComponent, {
            header: 'Salir de pantalla',
            data: { texto: '¿Está seguro de que quiere salir de la pantalla?' }
        });

        this.ref.onClose.subscribe((data: any) => {
            if (data) {
                //permanecemos en la página
                this.router.navigateByUrl("configuracion/tipos-documento");
            }
        });
    }

    borrar = (item: any) => {

        if (this.tipoDocumento?.tieneRegistros) {
            this.abrirDialogo();

        } else {
            this.ref = this.dialogService.open(DialogConfirmComponent, {
                header: 'Borrado',
                data: { texto: '¿Está seguro de borrar el tipo de documento?' }
            });

            this.ref.onClose.subscribe((data: any) => {
                if (data) {
                    this.tiposDocumentoService.delete(item).subscribe({
                        next: res => {
                            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tipo de documento borrado con éxito', life: 3000 });
                            this.router.navigateByUrl('configuracion/tipos-documento');
                        },
                        error: error => {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al borrar el tipo de documento', life: 3000 });
                        }
                    });
                }

            });
        }
    }

    abrirDialogo() {
        setTimeout(() => {
            this.ref = this.dialogService.open(DialogWarningComponent, {
                header: 'Borrado incorrecto',
                width: '500px',
                data: { texto: 'No se pudo borrar el tipo de documento porque existen registros asociados.' }
            });

            this.ref.onClose.subscribe((data: any) => {
            });
        }, 100);
    }

    mostrarAsterisco(control: string) {
        if (this.tipoDocumentoForm.controls[control].validator) {
            return "*";
        }
        return "";
    }
}
