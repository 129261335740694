<app-spinner></app-spinner>
<div class="flex flex-column" style="height: 100vh;">

    <div class="flex flex-1" [style]="'background-color: #F9F9FA'">
        
        <!-- formato extendido -->
        <div class="flex flex-column max-w-18rem" [@menuState]="!compact ? 'opened' : 'closed'">
            <!-- avatar usuario -->
            <div class="flex align-items-center">
                <i class="pi pi-user avatar"></i>
                <span class="elipsis" [pTooltip]="this.session.fulName.length > 23 ? this.session.fulName: ''">{{this.session.fulName}}</span>
                <img src="../../../../assets/images/menu_compact.png" alt="menuCompacto" class="cursor-pointer ml-auto mr-5" (click)="compact=!compact" [pTooltip]="'Plegar'">
            </div>
            <!-- menu -->
            <p-panelMenu #panelMenu [model]="items" [class]="'menu'"></p-panelMenu>
        </div>

        <!-- formato compacto -->
        <div class="flex flex-column"   style="width: 66px;align-items: center;" [@menuState]="compact ? 'opened' : 'closed'">
            <i class="pi pi-user avatar"></i>
            <i *ngFor="let item of items" [style]="item['styleCompact']" [class]="item.icon + ' cursor-pointer ' + activo(item)" (click)="navegar(item)" [pTooltip]="item.label"></i>
        </div>
        
        <!-- contenido -->
        <div class="flex-1 h-full bg-white border-round p-4 overflow-hidden">
            <!-- aqui el componente codigo miga-->
            <app-miga></app-miga>
            <router-outlet></router-outlet>
        </div>
    </div>

    <!-- footer -->
    <small class="flex flex-row justify-content-end footer" id="footer">
        Copyright © 2024 Cámara de Comercio de Madrid. Todos los derechos reservados.
    </small>
</div>