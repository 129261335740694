import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocalStorageService } from 'src/app/common/local-storage';
import { Tarea } from 'src/app/model/tarea';
import { TareasService } from 'src/app/services/tareas.service';
import { DialogConfirmComponent } from 'src/app/ui/common/dialog-confirm/dialog-confirm.component';
import { DialogWarningComponent } from 'src/app/ui/common/dialog-warning/dialogo-warning.component';

@Component({
    selector: 'app-edicion-tareas',
    templateUrl: './edicion-tareas.component.html',
    styleUrl: './edicion-tareas.component.scss'
})

export class EdicionTareasComponent implements OnInit {

    ref: DynamicDialogRef | undefined;

    tareaForm: FormGroup;
    idTarea: any;
    isEdit: boolean = false;
    tarea: Tarea | undefined;

    constructor(
        private fb: FormBuilder,
        public localStorage: LocalStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private tareasService: TareasService,
        private messageService: MessageService,
        public dialogService: DialogService,
    ) {
        this.tareaForm = this.fb.group({
            nombre: new FormControl<string | null>(null, Validators.required),
            descripcion: new FormControl<string | null>(null, Validators.required),
            tieneBeneficiario: new FormControl<boolean>(false),
        });
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.isEdit = true;
                this.tareaForm.patchValue(this.localStorage.get('tareaEdicion'));
                this.tarea = this.localStorage.get('tareaEdicion');
                this.idTarea = params['id'];
            }
        });
    }

    save() {
        const tarea: Tarea = this.tareaForm.value;
        tarea.nombre = tarea.nombre.trim();
        if (this.isEdit) {
            tarea.id = this.idTarea;
            this.tareasService.update(tarea).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tarea actualizada correctamente' });
                this.router.navigateByUrl('configuracion/tareas');
            });
        } else {
            this.tareasService.create(tarea).subscribe(() => {
                this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tarea creada correctamente' });
                this.router.navigateByUrl('configuracion/tareas');
            });
        }
    }

    cancelar() {

        this.ref = this.dialogService.open(DialogConfirmComponent, {
            header: 'Salir de pantalla',
            data: { texto: '¿Está seguro de que quiere salir de la pantalla?' }
        });

        this.ref.onClose.subscribe((data: any) => {
            if (data) {
                //permanecemos en la página
                this.router.navigateByUrl("configuracion/tareas");
            }
        });
    }

    abrirDialogo() {
        setTimeout(() => {
            this.ref = this.dialogService.open(DialogWarningComponent, {
                header: 'Borrado erróneo',
                width: '500px',
                data: { texto: 'No se pudo borrar la tarea porque existen imputaciones asociadas.' }
            });

            this.ref.onClose.subscribe((data: any) => {
                if (data) {
                    
                }

            });
        }, 100); // 3000 milisegundos = 3 segundos
    }

    borrar = (item: any) => {

        if (this.tarea?.tieneImputacion) {
            this.abrirDialogo();

        } else {
            this.ref = this.dialogService.open(DialogConfirmComponent, {
                header: 'Borrado',
                data: { texto: '¿Está seguro de borrar la tarea?' }
            });

            this.ref.onClose.subscribe((data: any) => {
                if (data) {
                    this.tareasService.delete(item).subscribe({
                        next: res => {
                            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Tarea borrada con éxito', life: 3000 });
                            this.router.navigateByUrl('configuracion/tareas');
                        },
                        error: error => {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al borrar la tarea', life: 3000 });
                        }
                    });
                }

            });
        }

    }
}
