import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, Observable } from 'rxjs';
import { EST_ACTIVO, EST_BORRADOR, EST_CERRADO } from 'src/app/common/constantes';
import { LocalStorageService } from 'src/app/common/local-storage';
import { Proyecto } from 'src/app/model/proyecto';
import { Roles } from 'src/app/model/roles';
import { ROLES_SIN_PROYECTO, Role } from 'src/app/model/session';
import { TableColum } from 'src/app/model/table-column';
import { Usuario } from 'src/app/model/usuario';
import { ProyectosService } from 'src/app/services/proyectos.service';
import { SessionService } from 'src/app/services/session-service';
import { TablaGenericaComponent } from 'src/app/ui/common/tabla-generica/tabla-generica.component';

@Component({
  selector: 'app-listado-clonacion-proyectos',
  templateUrl: './listado-clonacion-proyectos.component.html',
  styleUrl: './listado-clonacion-proyectos.component.scss',
  providers: [DatePipe]
})
export class ListadoClonacionProyectosComponent {

  ref: DynamicDialogRef | undefined;

  proyectos: Observable<Proyecto[]> | undefined;
  proyectosFiltrados: Observable<Proyecto[]> | undefined;
  nuevoNombre: string = '';
  proyectoSeleccionado: any;  // El proyecto que se va a duplicar
  esVisible: boolean = false;
  fechasInicioFin: Date[] | undefined;
  integrantes: boolean = false;
  delegados: boolean = false;
  estructuraEconomica: boolean = false;
  horasPlanificadas: boolean = false;
  tareas: boolean = false;
  conceptosSalariales: boolean = false;
  periodosProgramacion: boolean = false;

  columns: TableColum[] = [];

  @ViewChild('tabla') dt!: TablaGenericaComponent<Proyecto>;

   // Abre el diálogo para duplicar un proyecto
    duplicar = (proyecto: any) => {
        this.proyectoSeleccionado = proyecto;
        this.nuevoNombre = '';
        this.esVisible = true;
        this.fechasInicioFin = undefined;
        this.integrantes = false;
        this.delegados = false;
        this.estructuraEconomica = false;
        this.horasPlanificadas = false;
        this.tareas = false;
        this.conceptosSalariales = false;
        this.periodosProgramacion = false;
    } 

    estado = (item: Proyecto) => {
      switch (item.estado?.nombre) {
        case 'Activo':
          return 'proActivo'
        case 'Borrador':
          return 'proBorrador'
        case 'Cerrado':
          return 'proCerrado';
        default:
          return ''
      }
  }

  pro: any;
  constructor(
    public dialogService: DialogService, public messageService: MessageService, protected sessionService: SessionService,
    public localStorage: LocalStorageService, public proyectoService: ProyectosService<Proyecto>
  ) {

    this.proyectos = this.proyectoService.list({id_responsable: this.sessionService.get("user.id")});

    this.proyectosFiltrados = this.proyectos.pipe(
      map(proyectos => {
        this.pro = proyectos;
        return proyectos.filter(proyecto => this.estadoPermitido(proyecto, {roles: [Role.GESTOR, Role.ADMIN]}))
      })

    );


    if (localStorage.get('clonarProyectoSuccess')) {
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Proyecto clonado con éxito', life: 3000 });
      
      localStorage.remove('clonarProyectoSuccess');
    }

    this.columns = [
        {
          name : "nombre",
          cabecera: "Nombre",
          ancho: 'w-min'
        },
        {
          name : "estado.nombre",
          cabecera: "Estado",
          ancho: 'w-min',
          class: this.estado
        },
        {
          name : "responsable.fullName",
          cabecera: "Responsable",
          ancho: 'w-min'
        },
        {
          name : "acciones",
          cabecera: "Acciones",
          filter: false,
          ancho: 'w-min',
          sortable: false,
          exportable: false,
          acciones: [ 
              { 
                function: this.duplicar,
                icon: 'pi iconProDuplicar',
                tooltip: "Clonar proyecto",
                condicion: {funcion: this.estadoPermitido, parametros: {roles: [Role.GESTOR, Role.ADMIN]}}
              }
          ]
        }
      ];
  }

  puedeCrear() {
    return this.sessionService.role.find(rol => rol.nombre == Role.ADMIN);
  }

  manejarDuplicacion(respuesta: any) {
    if(respuesta){
      this.proyectoService.duplicarProyecto(respuesta)
      .subscribe(response => {    
        this.esVisible = false;
        localStorage.setItem('clonarProyectoSuccess', 'true');
        // añadir a los roles del usuario el proyecto creado
        this.sessionService.login(new Usuario({id: this.sessionService.get("user.id")})).subscribe(response => {
                  this.sessionService.saveLoginData(response);
                  window.location.reload();
            }
        );
      });
    } else {
      this.esVisible = false;
    }

  }

  onIntegrantesChange(isChecked: boolean): void {
    if (!isChecked) {
      this.horasPlanificadas = false; // Desmarca "horasPlanificadas" si "integrantes" está desmarcado
    }
  }

  estadoPermitido = (item: Proyecto, parametros: any) => {
    // comprueba que el usuario tenga el rol adecuado dentro del proyecto
    if (item.idPadre == null) {
      const proyectos = this.pro!.filter( (p: Proyecto) => p.idPadre == item.id);
      if (proyectos.length > 0) {
        const hijosPer = proyectos.forEach( (p:Proyecto) => this.estadoPermitido(p, parametros));
        return {...item, hijos:hijosPer};
      }
      

    }
     return parametros.roles?.find((a: string) =>
        this.sessionService.role.find((b: Roles) => b.nombre == a && (b.proyectos?.find(p => p.id == item.id) || ROLES_SIN_PROYECTO.find(rol => rol == b.nombre))));
    
  }

}
