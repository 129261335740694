import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { URL_HOME } from '../common/constantes';

@Injectable({
  providedIn: 'root'
})
export class PruebaAbertoService<T> {

  constructor(protected http: HttpClient) { }

  public llamadaBack(): Observable<T> {
    return this.http.get<T>(URL_HOME).pipe(
      take(1)
    );
}
}
