<div>
    <form [formGroup]="tipoDocumentoForm" class="flex flex-column gap-3 mt-5" (ngSubmit)="save()">
        <div class="flex flex-row gap-5 p-fluid">
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="nombre" placeholder="Nombre" />
                    <label for="nombre">Nombre *</label>
                    <div>
                        <small
                            *ngIf="tipoDocumentoForm.get('nombre')?.invalid && tipoDocumentoForm.get('nombre')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-3">
                <span class="p-float-label ">
                    <input type="text" pInputText formControlName="descripcion" placeholder="Descripción" />
                    <label for="descripcion">Descripción *</label>
                    <div>
                        <small
                            *ngIf="tipoDocumentoForm.get('descripcion')?.invalid && tipoDocumentoForm.get('descripcion')?.touched"
                            class="p-error">
                            Campo obligatorio
                        </small>
                    </div>

                </span>
            </div>
            <div class="field p-field w-3">
                <app-lista-desplegable [data]="tipos!" [seFiltra]="'false'" formControlName="tipo" [filtrarPor]="'nombre'" [texto]="'Tipo  '+ mostrarAsterisco('tipo')"></app-lista-desplegable>
                <small class="p-error" style="margin-top: -10px;" *ngIf="tipoDocumentoForm.get('tipo')?.errors?.hasOwnProperty('required') && tipoDocumentoForm.get('tipo')?.dirty">Campo obligatorio</small>
                <ng-container *ngIf="this.tipoDocumentoForm.value?.tipo?.nombre">
                    <ng-container *ngIf="this.tipoDocumentoForm.value?.tipo?.nombre === 'Genérica'; else esOperacional">
                        <small class="info-text"><i class="pi pi-info-circle" style="margin-right: 5px; color: rgb(139, 189, 255);"></i>Documentos generales asociados al proyecto</small>
                    </ng-container>
                    <ng-template #esOperacional>
                        <small class="info-text"><i class="pi pi-info-circle" style="margin-right: 5px; color: rgb(139, 189, 255);"></i>Documentos asociados a la estructura documental del proyecto</small>
                    </ng-template>
                </ng-container>
                
            </div>
        </div>
        <div class="flex pt-4 justify-content-end">
            <p-button label="Cancelar" class="mr-2" severity="secondary" (onClick)="cancelar()" />
            <p-button *ngIf="this.isEdit" icon="pi pi-trash"  label="Borrar" (click)="borrar(this.idTipoDocumento)" severity="danger" class="mr-2"></p-button>
            <p-button type="submit" icon="pi pi-save" label="Guardar" severity="success" [disabled]="tipoDocumentoForm.invalid" />
        </div>
    </form>
</div>