import { Injectable } from '@angular/core';
import { URL_HOME } from '../common/constantes';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { format } from 'date-fns';

const PATH = `${URL_HOME}/integraciones`;

@Injectable({
  providedIn: 'root'
})

export class IntegracionesService {

  constructor(protected http: HttpClient) { }

  obtenerNominas(fechaIni: Date, fechaFin: Date)  : Observable<Response> {
    return this.http.post<Response>(`${PATH}/obtenerNominas/${format(fechaIni, 'yyyy-MM-dd')}/${format(fechaFin, 'yyyy-MM-dd')}`, null).pipe(take(1));
  }

  obtenerFacturas(fecha: Date[])  : Observable<Response> {
    return this.http.post<Response>(`${PATH}/obtenerFacturas/${format(fecha[0], 'yyyy-MM-dd')}/${format(fecha[1], 'yyyy-MM-dd')}`, null).pipe(take(1));
  }
}
