import { AbstractControl, ValidatorFn } from '@angular/forms';

export function nifValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (!value) {
      return null;  // No realizar validación si no hay valor
    }

    const nifPattern = /^[0-9]{8}[A-Z]$/;
    if (!nifPattern.test(value)) {
      return { invalidFormat: true };
    }

    const letter = value.charAt(8);
    const numbers = parseInt(value.substr(0, 8), 10);
    const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const calculatedLetter = validLetters.charAt(numbers % 23);

    return letter === calculatedLetter ? null : { invalidLetter: true };
  };
}