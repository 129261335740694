import { NgModule } from '@angular/core';

import { TableModule } from 'primeng/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { PasswordModule } from 'primeng/password';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DividerModule } from 'primeng/divider';
import { StepperModule } from 'primeng/stepper';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { TablaGenericaComponent } from '../common/tabla-generica/tabla-generica.component';
import { ListaDesplegableComponent } from '../common/lista-desplegable/lista-desplegable.component';
import { FloatLabelModule } from 'primeng/floatlabel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, TreeDragDropService } from 'primeng/api';
import { DialogConfirmComponent } from '../common/dialog-confirm/dialog-confirm.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ResumenHorasComponent } from '../common/resumen-horas/resumen-horas.component';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { CarouselModule } from 'primeng/carousel';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogWarningComponent } from '../common/dialog-warning/dialogo-warning.component';
import { FileUploadModule } from 'primeng/fileupload';
import { TreeModule } from 'primeng/tree';
import { DialogFormComponent } from '../common/dialog-form/dialog-form.component';
import { MenuModule } from 'primeng/menu';
import { DialogUploadComponent } from '../common/dialog-upload/dialog-upload.component';
import { SubidaFicherosComponent } from '../common/subida-ficheros/subida-ficheros.component';
import { NgxEchartsDirective, provideEcharts } from 'ngx-echarts';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ListboxModule } from 'primeng/listbox';

@NgModule({
    declarations: [TablaGenericaComponent, 
                   ListaDesplegableComponent, 
                   DialogConfirmComponent, 
                   DialogWarningComponent, 
                   ResumenHorasComponent, 
                   DialogFormComponent,
                   DialogUploadComponent,
                   SubidaFicherosComponent],
    imports: [
        ReactiveFormsModule,
        ToastModule,
        HttpClientModule,
        InputTextModule,
        CheckboxModule,
        ButtonModule,
        RadioButtonModule,
        InputTextareaModule,
        DropdownModule,
        FormsModule,
        TableModule,
        CommonModule,
        CardModule,
        PasswordModule,
        PanelMenuModule,
        DividerModule,
        StepperModule,
        InputSwitchModule,
        CalendarModule,
        FloatLabelModule,
        DialogModule,
        ConfirmDialogModule,
        DynamicDialogModule,
        CarouselModule,
        InputNumberModule,
        MultiSelectModule,
        FileUploadModule,
        TreeModule,
        ProgressSpinnerModule,
        MenuModule,
        NgxEchartsDirective,
        SelectButtonModule,
        ListboxModule  
    ],
    providers: [
        ConfirmationService,
        TreeDragDropService,
        provideEcharts(),
    ],
    bootstrap: [],
    exports: [
        ToastModule,
        HttpClientModule,
        InputTextModule,
        CheckboxModule,
        ButtonModule,
        RadioButtonModule,
        InputTextareaModule,
        DropdownModule,
        FormsModule,
        TableModule,
        CommonModule,
        CardModule,
        PasswordModule,
        PanelMenuModule,
        DividerModule,
        TablaGenericaComponent,
        StepperModule,
        InputSwitchModule,
        CalendarModule,
        ListaDesplegableComponent,
        DialogConfirmComponent,
        FloatLabelModule,
        DialogModule,
        ConfirmDialogModule,
        DynamicDialogModule,
        ResumenHorasComponent,
        CarouselModule,
        InputNumberModule,
        MultiSelectModule,
        FileUploadModule,
        TreeModule,
        ProgressSpinnerModule,
        MenuModule,
        SubidaFicherosComponent,
        NgxEchartsDirective,
        SelectButtonModule,
        ListboxModule 
    ]
})
export class PrimeNgModule {

  constructor() {
    
  }

}
