import { URL_HOME } from '../common/constantes';
import { HttpClient } from '@angular/common/http';
import { CRUDServiceService } from './crudservice.service';
import { Injectable } from '@angular/core';
import { ConceptoSalarial } from '../model/conceptoSalarial';


const PATH = `${URL_HOME}/conceptos-salariales`;

@Injectable({
  providedIn: 'root'
})
export class ConceptosSalarialesService<T> extends CRUDServiceService<ConceptoSalarial> {

  constructor(protected override http: HttpClient) {
    super(PATH, http);
  }

}