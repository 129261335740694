import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/common/local-storage';
import { Roles } from 'src/app/model/roles';
import { ROLES_SIN_PROYECTO, Role } from 'src/app/model/session';
import { TableColum } from 'src/app/model/table-column';
import { Usuario } from 'src/app/model/usuario';
import { SessionService } from 'src/app/services/session-service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { DialogWarningComponent } from 'src/app/ui/common/dialog-warning/dialogo-warning.component';
import { TablaGenericaComponent } from 'src/app/ui/common/tabla-generica/tabla-generica.component';
import { DialogConfirmComponent } from '../../common/dialog-confirm/dialog-confirm.component';

@Component({
  selector: 'app-listado-usuarios',
  templateUrl: './listado-usuarios.component.html',
  styleUrl: './listado-usuarios.component.scss'
})
export class ListadoUsuariosComponent {

  ref: DynamicDialogRef | undefined;

  usuarios: Observable<Usuario[]> | undefined;

  edicion = (item: Usuario) => {
    this.localStorage.set('usuarioEdicion', item);
    this.router.navigateByUrl(`/configuracion/edicion-usuario/${item.id}`);
  };

  borrar = (item: Usuario) => {

    if (item.tieneImputaciones && !item.esAsignado) {
      this.messageService.add({ severity: 'warn', summary: 'CUIDADO', detail: 'Este usuario tiene imputaciones asociadas en uno o varios proyectos.', life: 3000 });
    
    } else if(item.esAsignado){
      this.messageService.add({ severity: 'warn', summary: 'CUIDADO', detail: 'Este usuario está asignado en uno o varios proyectos.', life: 3000 });

    } else {
      this.ref = this.dialogService.open(DialogConfirmComponent, {
        header: 'Borrado',
        data: { texto: '¿Está seguro de borrar el usuario?' }
      });

      this.ref.onClose.subscribe((data: any) => {
        if (data) {
          this.usuariosService.delete(item.id).subscribe({
            next: res => {
              this.dt.borrarRegistro(item);
              this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario borrado con éxito', life: 3000 });
            }
          });
        }

      });
    }
  }

  columns: TableColum[] = [];

  @ViewChild('tabla') dt!: TablaGenericaComponent<Usuario>;

  constructor(private usuariosService: UsuariosService, private router: Router,
    public dialogService: DialogService, public messageService: MessageService, private sessionService: SessionService,
    public localStorage: LocalStorageService
  ) {

    this.usuarios = this.usuariosService.list();

    this.columns = [
        {
            name: "nif",
            cabecera: "NIF",
            ancho: 'w-2'
        },
        {
            name: "nombre",
            cabecera: "Nombre",
            ancho: 'w-2'
        },
        {
            name: "apellidos",
            cabecera: "Apellidos",
            ancho: 'w-2'
        },
        {
            name: "idTipoContrato.nombre",
            cabecera: "Tipo de contrato",
            ancho: 'w-2',
            class: () => 'block overflow-hidden text-overflow-ellipsis w-50'
        },
        {
          name: "costePorDefecto",
          cabecera: "Coste por defecto",
          ancho: 'w-2'
        },
        {
            name: "acciones",
            cabecera: "Acciones",
            filter: false,
            ancho: 'w-1',
            sortable: false,
            exportable: false,
            acciones: [
                {
                    // accion editar
                    function: this.edicion,
                    icon: 'pi iconProConfig',
                    tooltip: "Edición",
                    condicion: { funcion: this.estadoPermitido, parametros: { roles: [Role.ADMIN, Role.RRHH] } }
                },
                {
                  function: this.borrar,
                  icon: 'pi pi-trash',
                  tooltip: "Borrar",
                  condicion: { funcion: this.estadoPermitido, parametros: { roles: [Role.ADMIN, Role.RRHH] } }
                }
            ]
        }
    ];
  }

  puedeCrear() {
    return this.sessionService.role.find(rol => rol.nombre == Role.ADMIN);
  }

  abrirDialogo() {
    setTimeout(() => {
      this.ref = this.dialogService.open(DialogWarningComponent, {
        header: 'Borrado incorrecto',
        width: '500px',
        data: { texto: 'No se pudo borrar el usuario porque existen imputaciones asociadas.' }
      });
    }, 100); 
  }

  nuevoUsuario() {
    this.localStorage.remove('usuarioEdicion');
    this.router.navigateByUrl('/configuracion/nuevo-usuario');
  }

  estadoPermitido = (item: Usuario, parametros: any) => {
    let flagRol;
    // comprueba que el usuario tenga el rol adecuado dentro del proyecto
    if (parametros.roles) {
      flagRol = parametros.roles?.find((a: string) =>
        this.sessionService.role.find((b: Roles) => b.nombre == a && (b.proyectos?.find(p => p.id == item.id) || ROLES_SIN_PROYECTO.find(rol => rol == b.nombre))))
    }
    return flagRol;
  }

}
