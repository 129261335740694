import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session-service';
import { HttpErrorResponse } from '@angular/common/http';
import { URL_INTRANET } from '../common/constantes';
import { Subject } from 'rxjs';
import { Usuario } from '../model/usuario';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuardService  {
    constructor(
        private router: Router,
        private session: SessionService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.session.isAuthenticated();
        if (route.queryParamMap.get('token')) {
                this.router.navigateByUrl("/validando");
                this.session.comprobarToken(route.queryParamMap.get('token')!, route.queryParamMap.get('appkey')!).subscribe({
                next: (response) => {
                    const subject = new Subject<boolean>();
                    if (response) {
                        // si el token es correcto, almacenamos el token y app_key y logamos al usuario en proyectos_publicos
                        this.session.tokenCC = response.token;
                        this.session.set('appkey', route.queryParamMap.get('appkey'));
                        this.session.login(new Usuario({numExpediente:response.username})).subscribe({
                        next: (response) => {
                            console.log("autenticado" + response);
                            //se guarda el token en session y se navega a la home
                            this.session.saveLoginData(response);
                            this.router.navigateByUrl("/home");
                        },
                        error: (error: HttpErrorResponse) => {
                            this.navegarLogin();
                        } 
                        }
                    );
                    } else {
                        this.navegarLogin();
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.navegarLogin();
                } 
            });
        } else if (state.url !== '/login') {
            this.session.isAuthenticated().subscribe(isAuth => {
                !isAuth && this.navegarLogin();;
            });
        }

        if (currentUser) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    navegarLogin() {
        if (environment.environmentName == 'development') {
            this.router.navigateByUrl('/login');
        } else {
            window.location.href =  URL_INTRANET;
        }
    }
}