import { Component } from '@angular/core';

@Component({
  selector: 'app-validando',
  templateUrl: './validando.component.html',
  styleUrl: './validando.component.scss'
})
export class ValidandoComponent {

}
